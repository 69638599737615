.note-editor {
        box-sizing: initial;
        background-color: #f5f5f5; //#444;

        p {
                margin: 0 0 3px;
        }
        .note-toolbar {
                &> .note-btn-group.note-style > button:first-child {
                        min-height: 19px;
                }
                .note-color .note-dropdown-menu {
                        min-width: unset;
                }
                .note-dropdown-menu {
                        &.note-check.dropdown-fontsize {
                                min-width: 55px;
                        }
                        box-sizing: initial;
                }
                .note-color-reset {
                        color: #FFF;
                }
                .note-holder-custom, .note-color-select, .note-color-select-btn {
                        display: none;
                }
                .note-btn {
                        padding: 5px 10px;
                }
                max-width: 770px;
                border-bottom: 0;
        }
        input[type="button"],
        .radio input[type="radio"],
        .radio-inline input[type="radio"],
        .checkbox input[type="checkbox"],
        .checkbox-inline input[type="checkbox"] {
                //float: left;
                margin-left: -20px;
                width: auto;
                height: auto;
        }

        .note-editing-area .note-editable {
                background-color: white;
                border-top: 1px solid #e2e2e2;

                /** {
                        color: unset;
                }*/

                table {
                        width: auto;

                        &.table-bordered {
                                td, th {
                                        min-width: 50px;
                                }
                        }

                        &:not(.table-bordered) {
                                td, th {
                                        border: none;
                                        padding: 0;
                                }
                        }
                }
        }

        .note-modal-header {
                button.close:before, button.close:after {
                        content: unset;
                }
        }

        .note-modal-footer {
                .btn:hover, .btn:focus, .btn:active {
                        border-radius: 20px;
                }
        }

        .modal-open {
                overflow: hidden;
        }
        .modal {
                display: none;
                overflow: auto;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1040;
        }
        .modal.fade .modal-dialog {
                -webkit-transform: translate(0, -25%);
                -ms-transform: translate(0, -25%);
                transform: translate(0, -25%);
                -webkit-transition: -webkit-transform 0.3s ease-out;
                -moz-transition: -moz-transform 0.3s ease-out;
                -o-transition: -o-transform 0.3s ease-out;
                transition: transform 0.3s ease-out;
        }
        .modal.in .modal-dialog {
                -webkit-transform: translate(0, 0);
                -ms-transform: translate(0, 0);
                transform: translate(0, 0);
        }
        .modal-dialog {
                margin-left: auto;
                margin-right: auto;
                width: auto;
                padding: 10px;
                z-index: 1050;
        }
        .modal-content {
                position: relative;
                background-color: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 6px;
                -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                background-clip: padding-box;
                outline: none;
        }
        .modal-header {
                padding: 15px;
                border-bottom: 1px solid #e5e5e5;
                min-height: 16.428571429px;
                button.close {
                        margin-top: -2px;
                }
        }
        .modal-title {
                margin: 0;
                line-height: 1.428571429;
        }
        .modal-body {
                position: relative;
                padding: 20px;
        }
        .modal-footer {
                margin-top: 15px;
                padding: 19px 20px 20px;
                text-align: right;
                border-top: 1px solid #e5e5e5;
                &:before {
                        content: " ";
                        display: table;
                }
                &:after {
                        content: " ";
                        display: table;
                        clear: both;
                }
                .btn + .btn {
                        margin-left: 5px;
                        margin-bottom: 0;
                }
                .btn-group .btn + .btn {
                        margin-left: -1px;
                }
                .btn-block + .btn-block {
                        margin-left: 0;
                }
        }
}

.note-editor .btn-primary:hover,
.note-editor .btn-primary:active, .note-editor .btn-primary.disabled,
.note-editor .btn-primary[disabled]{
    background-color: var(--bs-hover-color);
}

@media screen and (min-width: 768px) {
        .modal-dialog {
                width: 600px;
                padding-top: 30px;
                padding-bottom: 30px;
        }
        .modal-content {
                -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        }
}
