/*Fonts*/
@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url(' ../fonts/HelveticaNeueCyr-Bold.eot');
    src: url(' ../fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'), url(' ../fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'), url(' ../fonts/HelveticaNeueCyr-Bold.woff') format('woff'), url(' ../fonts/HelveticaNeueCyr-Bold.ttf') format('truetype'), url(' ../fonts/HelveticaNeueCyr-Bold.svg#HelveticaNeueCyr-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url(' ../fonts/HelveticaNeueCyr-Medium.eot');
    src: url(' ../fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'), url(' ../fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'), url(' ../fonts/HelveticaNeueCyr-Medium.woff') format('woff'), url(' ../fonts/HelveticaNeueCyr-Medium.ttf') format('truetype'), url(' ../fonts/HelveticaNeueCyr-Medium.svg#HelveticaNeueCyr-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Nexa-Bold';
    src: url(' ../fonts/Nexa-Bold.eot');
    src: url(' ../fonts/Nexa-Bold.eot?#iefix') format('embedded-opentype'), url(' ../fonts/Nexa-Bold.woff2') format('woff2'), url(' ../fonts/Nexa-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Nexa-Regular';
    src: url(' ../fonts/Nexa-Bold.eot');
    src: url(' ../fonts/Nexa-Bold.eot?#iefix') format('embedded-opentype'), url(' ../fonts/Nexa-Bold.woff2') format('woff2'), url(' ../fonts/Nexa-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.purchase-page {
    color: #000;
    font-family: Nexa-Regular,sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .5px;
}

.purchase-page h1 {
    font-family: Nexa-Regular,sans-serif;
    font-weight: 400;
    margin: 0;
}

.purchase-page input {
    height: auto;
}

.purchase-page .header {
    background: #fff;

    -webkit-box-shadow: 0 3px 26px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 26px -5px rgba(0, 0, 0, 0.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    left: 0;
    position: fixed;
    top: 0;
    -webkit-transition: background 0.5s, padding 0.4s, -webkit-transform 0.4s;
    transition: background 0.5s, padding 0.4s, -webkit-transform 0.4s;
    -o-transition: background 0.5s, padding 0.4s, transform 0.4s;
    transition: background 0.5s, padding 0.4s, transform 0.4s;
    transition: background 0.5s, padding 0.4s, transform 0.4s, -webkit-transform 0.4s;
    width: 100%;
    z-index: 100;
}
.purchase-page .header-logo {
    display: inline-block;
    position: relative;
    width: 120px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .header-logo {
        width: 162px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .header-logo.color-white .logo-image {
        opacity: 1;
        -webkit-transition-delay: 0.1s;
        -o-transition-delay: 0.1s;
        transition-delay: 0.1s;
        visibility: visible;
    }
    .purchase-page .header-logo.color-white .color-logo-image {
        opacity: 0;
    }
}
.purchase-page .logo-image {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 1;
}
.purchase-page .color-logo-image, .purchase-page .logo-image {
    -webkit-transition: opacity 0.4s, visibility 0.4s;
    -o-transition: opacity 0.4s, visibility 0.4s;
    transition: opacity 0.4s, visibility 0.4s;
}
.purchase-page .header-wrap {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    z-index: 2;
}
@media only screen and (min-width: 720px) {
    .purchase-page .header-wrap {
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .header-wrap {
        padding: 1.56vw 30px 1.04vw 60px;
        -webkit-transition: padding 0.4s;
        -o-transition: padding 0.4s;
        transition: padding 0.4s;
    }
}
@media only screen and (min-width: 1367px) {
    .purchase-page .header-wrap {
        padding-top: 2.08vw;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .header {
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .purchase-page .header.sticky {
        background: #fff;
        -webkit-box-shadow: 0 3px 26px -5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 3px 26px -5px rgba(0, 0, 0, 0.2);
    }
    .purchase-page .header.sticky .header-wrap {
        padding-bottom: 1.04vw;
        padding-top: 1.04vw;
    }
    .purchase-page .header.sticky .header-logo.color-white .logo-image {
        opacity: 0;
        visibility: hidden;
    }
    .purchase-page .header.sticky .header-logo.color-white .color-logo-image {
        opacity: 1;
    }
    .purchase-page .header.down-state {
        -webkit-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    .purchase-page .header.down-state.sticky {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
.purchase-page .header-logo-container {
    display: inline-block;
    vertical-align: middle;
}
.purchase-page .header-items-container {
    display: inline-block;
}
.purchase-page .template-image {
    display: inline-block;
    height: auto;
    width: 100%;
}
.purchase-page .header.sticky .header-logo.color-white .color-logo-image {
    opacity: 1;
}
.purchase-page .request-demo-btn-container {
    display: none;
}
@media only screen and (min-width: 720px) {
    .purchase-page .request-demo-btn-container {
        display: inline-block;
        margin-right: 30px;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .request-demo-btn-container {
        margin-right: 1.82vw;
    }
}
@media only screen and (min-width: 720px) {
    .purchase-page .mobile-request-demo-btn-container {
        display: none;
    }
}

.purchase-page .quantity-input-label {
    display: block;
    font-size: 16px;
    padding-right: 30px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .quantity-input-label {
        display: inline-block;
        margin-top: 0;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .quantity-input-label {
        padding-right: 1.56vw;
    }
}
.purchase-page .quantity-input {
    background: #fff;
    border: none;
    border-radius: 50px;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #000;
    display: block;
    font-size: 18px;
    margin-top: 10px;
    padding: 15px 25px;
    width: 120px;
    height: auto !important;
}
@media only screen and (min-width: 720px) {
    .purchase-page .quantity-input {
        display: inline-block;
        margin-top: 0;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .quantity-input {
        padding: 0.78vw 1.3vw;
        width: 6.25vw;
    }
}
@media only screen and (min-width: 1441px) {
    .purchase-page .quantity-input {
        font-size: 24px;
    }
}

.purchase-page .link-btn {
    background: #9ee3b4;
    border-radius: 100px;
    -webkit-box-shadow: 25px 25px 45px -22px rgba(0, 0, 0, 0.2);
    box-shadow: 25px 25px 45px -22px rgba(0, 0, 0, 0.2);
    color: #000;
    font-family: Nexa-Bold, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1em;
    padding: 20px 25px;
    text-align: center;
}
.purchase-page .link-btn.small-btn {
    font-size: 14px;
    padding: 15px 25px;
}
.purchase-page .link-btn.white {
    background: #fff;
}
.purchase-page .link-btn.purple {
    background: #cad6eb;
}
.purchase-page .link-btn.purple-dark {
    background: #b0c4e8;
}
.purchase-page .link-btn.purple-green {
    background: #cad6eb;
}
.purchase-page .link-btn .link-btn-image {
    margin-right: 10px;
    max-height: 100%;
    max-width: 100%;
}
.purchase-page .link-btn .link-btn-image, .purchase-page .link-btn .link-btn-text {
    display: inline-block;
    vertical-align: middle;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .link-btn {
        padding: 1.56vw 2.6vw;
        -webkit-transition: color 0.4s, background 0.4s;
        -o-transition: color 0.4s, background 0.4s;
        transition: color 0.4s, background 0.4s;
    }
    .purchase-page .link-btn:hover {
        background: #b0c4e8;
    }
    .purchase-page .link-btn:hover.purple-dark, .purchase-page .link-btn:hover.purple-green, .purchase-page .link-btn:hover.white {
        background: #9ee3b4;
    }
    .purchase-page .link-btn.small-btn {
        padding: 1.04vw 1.56vw;
    }
}
.purchase-page .total-wrap {
    margin-top: 76px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .total-wrap {
        margin-top: 78px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .total-wrap {
        margin-top: 0;
    }
}
.purchase-page .text-page-template {
    padding-bottom: 50px;
}
.purchase-page .text-page-template .wedocs-docs-single {
    background: #edf1f8;
    border: none !important;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap {
    margin-top: 20px;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single h3 {
    border-bottom: 0;
    color: #000;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.3em;
    padding: 0;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single h3 a {
    color: #000;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single .inside {
    margin-top: 30px;
    padding: 0;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections li {
    margin-bottom: 10px;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections li:last-child {
    margin-bottom: 0;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections li a {
    color: #000;
    display: inline-block;
    font-size: 18px;
    text-decoration: underline;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single .wedocs-doc-link {
    background: transparent;
    border-top: none;
    margin-top: 30px;
    padding: 0;
    text-align: left;
}
.purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single .wedocs-doc-link a {
    background: #cad6eb;
    border-radius: 100px;
    -webkit-box-shadow: 25px 25px 45px -22px rgba(0, 0, 0, 0.2);
    box-shadow: 25px 25px 45px -22px rgba(0, 0, 0, 0.2);
    color: #000;
    display: inline-block;
    font-family: Nexa-Bold, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1em;
    padding: 20px 25px;
    text-align: center;
    vertical-align: middle;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .text-page-template {
        padding-bottom: 3.85vw;
    }
    .purchase-page .text-page-template .wedocs-docs-single {
        padding: 4.69vw 6.77vw 3.65vw;
        -webkit-transition: background 0.4s;
        -o-transition: background 0.4s;
        transition: background 0.4s;
    }
    .purchase-page .text-page-template .wedocs-docs-single:hover {
        background: #9ee3b4;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single .inside {
        margin-top: 2.6vw;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections li {
        margin-bottom: 0.78vw;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections li a {
        -webkit-transition: color 0.4s;
        -o-transition: color 0.4s;
        transition: color 0.4s;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections li a:hover {
        color: #456aab;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single .wedocs-doc-link {
        margin-top: 3.65vw;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single .wedocs-doc-link a {
        padding: 1.56vw 2.6vw;
        -webkit-transition: color 0.4s, background 0.4s;
        -o-transition: color 0.4s, background 0.4s;
        transition: color 0.4s, background 0.4s;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single .wedocs-doc-link a:hover {
        background: #b0c4e8;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single {
        margin-bottom: 3%;
    }
}
@media only screen and (min-width: 1367px) {
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list li.wedocs-docs-single h3 {
        font-size: 1.88vw;
        line-height: 1.2em;
    }
    .purchase-page .text-page-template .wedocs-shortcode-wrap ul.wedocs-docs-list ul.wedocs-doc-sections li a {
        font-size: 20px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .block-page .inner-page-title-wrap {
        width: 46%;
    }
    .purchase-page .block-intro-content {
        width: 40%;
    }
}
.purchase-page .purchase-licenses-page .template-grid-item-inner {
    background: #e9eef7;
}
.purchase-page .purchase-licenses-page .two-col-text-item {
    padding: 30px 20px;
    width: 100%;
}
.purchase-page .purchase-licenses-page .two-col-text-item .text-page-block h3 {
    font-family: Nexa-Bold, sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}
.purchase-page .purchase-licenses-page .text-page-block {
    margin-bottom: 0;
}
.purchase-page .purchase-licenses-page .text-page-block ul li:after {
    border-color: #9ee3b4;
    height: 5px;
    width: 10px;
}
.purchase-page .purchase-licenses-page .text-page-block ul li ul li:after {
    background: #9ee3b4;
    border: none;
    border-radius: 10px;
    height: 7px;
    width: 7px;
}
.purchase-page .purchase-licenses-page .template-grid {
    margin-top: 30px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .purchase-licenses-page .two-col-text-item {
        padding: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .purchase-licenses-page .two-col-text-item {
        padding: 3.65vw 3.91vw 4.43vw;
    }
    .purchase-page .purchase-licenses-page .template-grid {
        margin-top: 0;
    }
}
@media only screen and (min-width: 1367px) {
    .purchase-page .purchase-licenses-page .template-grid {
        margin-left: -3.13vw;
        margin-right: -3.13vw;
    }
    .purchase-page .purchase-licenses-page .template-grid-item {
        padding-left: 3.13vw;
        padding-right: 3.13vw;
    }
}
.purchase-page .purchase-page.main-product-page .intro-top-holder {
    background: #fff;
}
.purchase-page .inner-top-section {
    background: #fff;
    padding-bottom: 10px;
    padding-top: 50px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .inner-top-section {
        padding-bottom: 3.13vw;
        padding-top: 11.46vw;
    }
}
.purchase-page .main-wrap {
    padding-left: 20px;
    padding-right: 20px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .main-wrap {
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .main-wrap {
        padding-left: 10.42vw;
        padding-right: 10.42vw;
    }
}
.purchase-page .breadcrumb-row {
    font-size: 0;
    margin-bottom: 25px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .breadcrumb-row {
        margin-bottom: 10px;
    }
}
.purchase-page .breadcrumb-link {
    color: #90a2c2;
    display: inline-block;
    font-size: 16px;
    position: relative;
    vertical-align: middle;
}
@media only screen and (min-width: 1025px) {
    .purchase-page a.breadcrumb-link {
        -webkit-transition: color 0.4s;
        -o-transition: color 0.4s;
        transition: color 0.4s;
    }
    .purchase-page a.breadcrumb-link:hover {
        color: #000;
    }
}
.purchase-page .breadcrumb-separator {
    color: #90a2c2;
    font-size: 18px;
    margin-bottom: -3px;
    padding: 5px;
}
.purchase-page .breadcrumb-separator, .purchase-page .link-btn {
    display: inline-block;
    vertical-align: middle;
}
.purchase-page .z-1 {
    z-index: 1;
}
.purchase-page .z-2 {
    z-index: 2;
}
.purchase-page .main-title {
    font-size: 32px;
    line-height: 1.3em;
}
.purchase-page .main-title.white {
    color: #fff;
}
.purchase-page .main-title.white strong {
    color: #91a6cb;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .main-title {
        font-size: 2.5vw;
        line-height: 1.2em;
    }
}
.purchase-page .block-intro-section {
    z-index: 3;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .block-intro-section {
        min-height: 19.79vw;
    }
}
.purchase-page .block-intro-section {
    z-index: 3;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .block-intro-section {
        min-height: 19.79vw;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .featured-title-wrap {
        padding-top: 4.69vw;
    }
    .purchase-page .template-grid {
        margin-left: -1.04vw;
        margin-right: -1.04vw;
    }
}
.purchase-page .fz-0 {
    font-size: 0;
    letter-spacing: 0;
}
.purchase-page .template-grid-item {
    display: inline-block;
    vertical-align: top;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .template-grid-item {
        padding-left: 1.04vw;
        padding-right: 1.04vw;
    }
}
.purchase-page .template-grid-item-inner {
    background: #f0f3f7;
}
.purchase-page .template-grid-item-inner .intro-bottom-container {
    margin-top: 20px;
    padding: 0;
}
.purchase-page .template-grid-item-inner .intro-bottom-item {
    margin-bottom: 20px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .template-grid-item-inner .intro-bottom-container {
        margin-top: 2.6vw;
    }
    .purchase-page .template-grid-item-inner .intro-bottom-item {
        margin-bottom: 30px;
    }
}
.purchase-page .col {
    display: inline-block;
    vertical-align: top;
}
@media (max-width: 719px) {
    .purchase-page .col-m-25 {
        width: 25%;
    }
    .purchase-page .col-m-30 {
        width: 30%;
    }
    .purchase-page .col-m-50 {
        width: 50%;
    }
    .purchase-page .col-m-70 {
        width: 70%;
    }
    .purchase-page .col-m-75 {
        width: 75%;
    }
    .purchase-page .col-m-80 {
        width: 80%;
    }
    .purchase-page .col-m-90 {
        width: 90%;
    }
    .purchase-page .col-m-100 {
        width: 100%;
    }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
    .purchase-page .col-t-25 {
        width: 25%;
    }
    .purchase-page .col-t-30 {
        width: 30%;
    }
    .purchase-page .col-t-32 {
        width: 32%;
    }
    .purchase-page .col-t-33 {
        width: 33.333%;
    }
    .purchase-page .col-t-35 {
        width: 35%;
    }
    .purchase-page .col-t-40 {
        width: 40%;
    }
    .purchase-page .col-t-54 {
        width: 54%;
    }
    .purchase-page .col-t-50 {
        width: 50%;
    }
    .purchase-page .col-t-46 {
        width: 46%;
    }
    .purchase-page .col-t-60 {
        width: 60%;
    }
    .purchase-page .col-t-65 {
        width: 65%;
    }
    .purchase-page .col-t-66 {
        width: 66%;
    }
    .purchase-page .col-t-70 {
        width: 70%;
    }
    .purchase-page .col-t-80 {
        width: 80%;
    }
    .purchase-page .col-t-85 {
        width: 85%;
    }
    .purchase-page .col-t-100 {
        width: 100%;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .col-d-100 {
        width: 100%;
    }
    .purchase-page .col-d-80 {
        width: 80%;
    }
    .purchase-page .col-d-78 {
        width: 78%;
    }
}
@media (min-width: 1025px) and (max-width: 1660px) {
    .purchase-page .col-d-78 {
        width: 70%;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .col-d-75 {
        width: 75%;
    }
    .purchase-page .col-d-72 {
        width: 72%;
    }
    .purchase-page .col-d-71 {
        width: 71%;
    }
    .purchase-page .col-d-70 {
        width: 70%;
    }
    .purchase-page .col-d-67 {
        width: 67%;
    }
    .purchase-page .col-d-66 {
        width: 66.66%;
    }
    .purchase-page .col-d-65 {
        width: 65%;
    }
    .purchase-page .col-d-63 {
        width: 63%;
    }
    .purchase-page .col-d-61 {
        width: 61%;
    }
    .purchase-page .col-d-605 {
        width: 60.7%;
    }
    .purchase-page .col-d-60 {
        width: 60%;
    }
    .purchase-page .col-d-57 {
        width: 57%;
    }
    .purchase-page .col-d-58 {
        width: 58%;
    }
    .purchase-page .col-d-56 {
        width: 56%;
    }
    .purchase-page .col-d-55 {
        width: 55%;
    }
    .purchase-page .col-d-54 {
        width: 54%;
    }
    .purchase-page .col-d-50 {
        width: 50%;
    }
    .purchase-page .col-d-46 {
        width: 46%;
    }
    .purchase-page .col-d-42 {
        width: 42%;
    }
    .purchase-page .col-d-85 {
        width: 85%;
    }
    .purchase-page .d-offset-left-50 {
        margin-left: 50%;
    }
    .purchase-page .col-d-47 {
        width: 47%;
    }
    .purchase-page .col-d-44, .purchase-page .col-d-45 {
        width: 44%;
    }
    .purchase-page .col-d-40 {
        width: 40%;
    }
    .purchase-page .col-d-38 {
        width: 38%;
    }
    .purchase-page .col-d-37 {
        width: 37%;
    }
    .purchase-page .col-d-36 {
        width: 36%;
    }
    .purchase-page .col-d-35 {
        width: 35%;
    }
    .purchase-page .col-d-32 {
        width: 31.66%;
    }
    .purchase-page .col-d-33 {
        width: 33.33%;
    }
    .purchase-page .col-d-31 {
        width: 31%;
    }
    .purchase-page .col-d-30 {
        width: 30%;
    }
    .purchase-page .col-d-29 {
        width: 29%;
    }
    .purchase-page .col-d-28 {
        width: 28%;
    }
    .purchase-page .col-d-25 {
        width: 25%;
    }
    .purchase-page .col-d-22 {
        width: 22%;
    }
}
@media (min-width: 1025px) and (max-width: 1660px) {
    .purchase-page .col-d-22 {
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 30%;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .col-d-20 {
        width: 20%;
    }
}
.purchase-page .two-col-text-item ol {
    color: #687b9d;
    font-size: 13px;
    margin: 0;
    padding-left: 20px;
    text-transform: uppercase;
}
.purchase-page .two-col-text-item ol li {
    line-height: 1.1em;
    margin-bottom: 15px;
}
.purchase-page .two-col-text-item ol li:last-child {
    margin-bottom: 0;
}
.purchase-page .two-col-text-item ol ul {
    color: #000;
    font-size: 16px;
    margin-left: -20px;
    margin-top: 15px;
    text-transform: none;
}
.purchase-page .two-col-text-item ol ul li {
    margin-bottom: 5px;
    padding-left: 20px;
}
.purchase-page .two-col-text-item ol ul li:after {
    border-color: #9ee3b4;
    height: 5px;
    width: 9px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .two-col-text-item {
        display: inline-block;
        padding-right: 9.38vw;
        vertical-align: middle;
        width: 48%;
    }
    .purchase-page .two-col-text-item ol li {
        margin-bottom: 2.08vw;
    }
    .purchase-page .two-col-text-item ol ul li {
        margin-bottom: 5px;
    }
}
.purchase-page .regular-title {
    font-size: 26px;
    line-height: 1.3em;
}
@media only screen and (min-width: 1367px) {
    .purchase-page .regular-title {
        font-size: 1.88vw;
        line-height: 1.2em;
    }
}
.purchase-page .text-page-block {
    color: #000;
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 1.5em;
    margin-bottom: 50px;
}
.purchase-page .text-page-block:last-child {
    margin-bottom: 0;
}
.purchase-page .text-page-block h1 {
    font-size: 32px;
}
.purchase-page .text-page-block h1, .purchase-page .text-page-block h2 {
    font-weight: 400;
    line-height: 1.2em;
    margin-bottom: 1.3em;
    margin-top: 1.3em;
}
.purchase-page .text-page-block h2 {
    font-size: 30px;
}
.purchase-page .text-page-block h3 {
    font-size: 26px;
}
.purchase-page .text-page-block h3, .purchase-page .text-page-block h4 {
    font-weight: 400;
    line-height: 1.2em;
    margin-bottom: 1.3em;
    margin-top: 1.3em;
}
.purchase-page .text-page-block h4 {
    font-size: 22px;
}
.purchase-page .text-page-block h5 {
    font-size: 20px;
}
.purchase-page .text-page-block h5, .purchase-page .text-page-block h6 {
    font-weight: 400;
    line-height: 1.2em;
    margin-bottom: 1.3em;
    margin-top: 1.3em;
}
.purchase-page .text-page-block h6 {
    font-size: 18px;
}
.purchase-page .text-page-block strong {
    font-family: Nexa-Bold, sans-serif;
    font-weight: 700;
}
.purchase-page .text-page-block a:not(.link-btn):not(.underline-link-btn) {
    color: #5682d0;
    font-weight: 300;
    text-decoration: underline;
}
.purchase-page .text-page-block ul {
    list-style-type: none;
    margin: 20px 0 0;
    padding: 0;
}
.purchase-page .text-page-block ul li {
    line-height: 1.5em;
    padding-left: 22px;
    position: relative;
}
.purchase-page .text-page-block ul li:after {
    border-bottom: 2px solid #5682d0;
    border-left: 2px solid #5682d0;
    content: "";
    display: inline-block;
    height: 6px;
    left: 0;
    position: absolute;
    top: 5px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 12px;
}
.purchase-page .text-page-block li {
    margin-bottom: 10px;
}
.purchase-page .text-page-block p {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}
.purchase-page .text-page-block sup {
    font-size: 0.7em;
    line-height: 1em;
}
.purchase-page .text-page-block .tab-section-container {
    background: #edf1f8;
}
.purchase-page .text-page-block .tab-section-container .regular-title {
    font-size: 26px;
}
.purchase-page .text-page-block .tab-section-container .link-btn {
    color: #000;
    text-decoration: none;
}
.purchase-page .text-page-block .tab-section-container .tab-section-content {
    max-width: 100%;
}
.purchase-page .text-page-block .tab-section-container .regular-title {
    margin-bottom: 0;
    margin-top: 0;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .text-page-block {
        margin-bottom: 5.21vw;
    }
    .purchase-page .text-page-block a:not(.link-btn) {
        -webkit-transition: opacity 0.4s;
        -o-transition: opacity 0.4s;
        transition: opacity 0.4s;
    }
    .purchase-page .text-page-block a:not(.link-btn):hover {
        opacity: 0.6;
    }
}
.purchase-page .mt-60 {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-60 {
        margin-top: 3.13vw;
    }
}
.purchase-page .mt-5, .purchase-page .mt-10 {
    margin-top: 10px;
}
.purchase-page .mt-15 {
    margin-top: 15px;
}
.purchase-page .mt-20 {
    margin-top: 20px;
}
.purchase-page .mt-25 {
    margin-top: 25px;
}
.purchase-page .mt-30, .purchase-page .mt-40 {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-40 {
        margin-top: 2.08vw;
    }
}
.purchase-page .mt-50 {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-50 {
        margin-top: 2.6vw;
    }
}
.purchase-page .mt-60 {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-60 {
        margin-top: 3.13vw;
    }
}
.purchase-page .mt-70 {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-70 {
        margin-top: 3.65vw;
    }
}
.purchase-page .mt-80 {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-80 {
        margin-top: 4.17vw;
    }
}
.purchase-page .mt-100 {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-100 {
        margin-top: 4.17vw;
    }
}
.purchase-page .mt-125 {
    margin-top: 50px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-125 {
        margin-top: 6.51vw;
    }
}
.purchase-page .mt-190 {
    margin-top: 50px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .mt-190 {
        margin-top: 9.9vw;
    }
}
.purchase-page .demo-form-col iframe {
    background: #93abd5;
    border: none;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 1500px;
    max-height: 1460px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}
.purchase-page .demo-form-col .template-grid-item-inner {
    background: #93abd5;
}
.purchase-page .demo-form-col .title-wrap {
    background: #93abd5;
    padding: 30px 20px 0;
    text-align: center;
}
@media only screen and (min-width: 720px) {
    .purchase-page .demo-form-col .title-wrap {
        padding: 40px 40px 0;
    }
    .purchase-page .demo-form-col iframe {
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .demo-form-col .title-wrap {
        padding: 3.13vw 2.86vw 1.04vw;
    }
    .purchase-page .demo-form-col iframe {
        padding-left: 2.6vw;
        padding-right: 2.6vw;
    }
}
@media only screen and (min-width: 1661px) {
    .purchase-page .demo-form-col iframe {
        max-height: 1460px;
    }
}
.purchase-page .licenses-row {
    background: #d4ddee;
    padding: 30px 20px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .licenses-row {
        padding: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .licenses-row {
        padding: 2.34vw 3.39vw;
    }
}
.purchase-page .top-price-row {
    background: #e2f7e8;
    border-bottom: 1px solid #fff;
    padding: 30px 20px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .top-price-row {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        padding: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .top-price-row {
        padding: 3.13vw 3.39vw;
    }
}
.purchase-page .top-price-large-text {
    color: #64d187;
    display: block;
    font-size: 20px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .top-price-large-text {
        display: inline-block;
        padding-right: 10px;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .top-price-large-text {
        font-size: 1.88vw;
    }
}
.purchase-page .top-price-row {
    background: #e2f7e8;
    border-bottom: 1px solid #fff;
    padding: 30px 20px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .top-price-row {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        padding: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .top-price-row {
        padding: 3.13vw 3.39vw;
    }
}
.purchase-page .top-price-large-text {
    color: #64d187;
    display: block;
    font-size: 20px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .top-price-large-text {
        display: inline-block;
        padding-right: 10px;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .top-price-large-text {
        font-size: 1.88vw;
    }
}
.purchase-page .top-price {
    display: block;
    font-size: 40px;
    margin-top: 10px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .top-price {
        display: inline-block;
        margin-top: 0;
        padding-left: 10px;
        padding-right: 10px;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .top-price {
        font-size: 2.5vw;
    }
}
.purchase-page .top-price-small-text {
    color: #64d187;
    display: block;
    font-size: 18px;
    margin-top: 10px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .top-price-small-text {
        display: inline-block;
        margin-top: 0;
        padding-left: 10px;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .top-price-small-text {
        font-size: 16px;
    }
}
@media only screen and (min-width: 1367px) {
    .purchase-page .top-price-small-text {
        font-size: 18px;
    }
}
.purchase-page .top-price-small-text {
    color: #64d187;
    display: block;
    font-size: 18px;
    margin-top: 10px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .top-price-small-text {
        display: inline-block;
        margin-top: 0;
        padding-left: 10px;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .top-price-small-text {
        font-size: 16px;
    }
}
@media only screen and (min-width: 1367px) {
    .purchase-page .top-price-small-text {
        font-size: 18px;
    }
}
.purchase-page .licenses-row {
    background: #d4ddee;
    padding: 30px 20px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .licenses-row {
        padding: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .licenses-row {
        padding: 2.34vw 3.39vw;
    }
}
.purchase-page .quantity-form-row-item {
    display: inline-block;
    padding-right: 20px;
    vertical-align: middle;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .quantity-form-row-item {
        padding-right: 1.04vw;
    }
}
.purchase-page .quantity-total-text {
    display: block;
    font-size: 16px;
    margin-top: 10px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .quantity-total-text {
        display: inline-block;
        margin-top: 0;
        vertical-align: middle;
    }
}
.purchase-page .underline-row {
    border-bottom: 1px solid #9ee3b4;
    padding: 30px 20px;
}
.purchase-page .underline-row .underline-link-btn {
    color: #000;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}
.purchase-page .underline-row .underline-link-btn .btn-line-text {
    font-size: 16px;
}
.purchase-page .underline-row .underline-link-btn .line-wrap {
    margin-top: 0;
}
.purchase-page .underline-row .underline-link-btn .line:after, .purchase-page .underline-row .underline-link-btn .line:before {
    background: #000;
}
@media only screen and (min-width: 720px) {
    .purchase-page .underline-row {
        padding: 40px;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .underline-row {
        padding: 2.34vw 3.39vw;
    }
    .purchase-page .underline-row.amount-row {
        padding-bottom: 1.56vw;
        padding-right: 0;
        padding-top: 1.56vw;
    }
}
.purchase-page .price-row-text {
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
}
.purchase-page .price-row-text strong {
    font-family: Nexa-Bold, sans-serif;
    font-weight: 700;
}
.purchase-page .underline-link-btn {
    color: #686da0;
    display: inline-block;
    font-size: 0;
    position: relative;
    text-decoration: none !important;
}
.purchase-page .underline-link-btn.underline .btn-line-text {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(#000));
    background-image: -o-linear-gradient(#000, #000);
    background-image: linear-gradient(#000, #000);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    display: inline;
    overflow: hidden;
}
.purchase-page .underline-link-btn.underline.gray .btn-line-text {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#aaa), to(#aaa));
    background-image: -o-linear-gradient(#aaa, #aaa);
    background-image: linear-gradient(#aaa, #aaa);
}
.purchase-page .underline-link-btn.underline.blue .btn-line-text {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5682d0), to(#5682d0));
    background-image: -o-linear-gradient(#5682d0, #5682d0);
    background-image: linear-gradient(#5682d0, #5682d0);
}
.purchase-page .underline-link-btn.black-blue {
    color: #000;
    font-family: Nexa-Bold, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}
.purchase-page .underline-link-btn.blue {
    color: #5682d0;
}
.purchase-page .underline-link-btn.blue .line:after, .purchase-page .underline-link-btn.blue .line:before {
    background: #5682d0;
}
.purchase-page .underline-link-btn.blue-1 {
    color: #4f77bc;
}
.purchase-page .underline-link-btn.blue-1 .line:after, .purchase-page .underline-link-btn.blue-1 .line:before {
    background: #4f77bc;
}
.purchase-page .underline-link-btn.black {
    color: #000;
}
.purchase-page .underline-link-btn.black .line:after, .purchase-page .underline-link-btn.black .line:before {
    background: #000;
}
.purchase-page .underline-link-btn.white {
    color: #fff;
}
.purchase-page .underline-link-btn.white .line:after, .purchase-page .underline-link-btn.white .line:before {
    background: #fff;
}
.purchase-page .underline-link-btn.purple {
    color: #a8a3d1;
}
.purchase-page .underline-link-btn.purple .line:after, .purchase-page .underline-link-btn.purple .line:before {
    background: #a8a3d1;
}
.purchase-page .underline-link-btn.gray {
    color: #aaa;
}
.purchase-page .underline-link-btn.gray .line:after, .purchase-page .underline-link-btn.gray .line:before {
    background: #aaa;
}
.purchase-page .underline-link-btn .underline-link-btn-inner {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}
.purchase-page .underline-link-btn .underline-image {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    width: 23px;
}
.purchase-page .underline-link-btn .btn-line-text {
    display: inline-block;
    font-size: 15px;
}
.purchase-page .underline-link-btn .line-wrap {
    background: transparent;
    display: inline-block;
    height: 1px;
    margin-top: 2px;
    position: relative;
    vertical-align: middle;
    width: 100%;
}
.purchase-page .underline-link-btn .line {
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.purchase-page .underline-link-btn .line:before {
    left: 0;
    width: 0;
}
.purchase-page .underline-link-btn .line:after, .purchase-page .underline-link-btn .line:before {
    background: #686da0;
    content: "";
    height: 1px;
    position: absolute;
    top: 0;
    -webkit-transition: width 0.01s;
    -moz-transition: width 0.01s;
    -ms-transition: width 0.01s;
    -o-transition: width 0.01s;
    transition: width 0.01s;
}
.purchase-page .underline-link-btn .line:after {
    right: 0;
    width: 100%;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .underline-link-btn.underline {
        -webkit-transition: opacity 0.4s, color 0.4s !important;
        -o-transition: opacity 0.4s, color 0.4s !important;
        transition: opacity 0.4s, color 0.4s !important;
    }
    .purchase-page .underline-link-btn:hover.underline {
        opacity: 0.7;
    }
    .purchase-page .underline-link-btn:hover.underline.gray {
        opacity: 1;
    }
    .purchase-page .underline-link-btn:hover.underline.gray .btn-line-text {
        background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(#000));
        background-image: -o-linear-gradient(#000, #000);
        background-image: linear-gradient(#000, #000);
    }
    .purchase-page .underline-link-btn:hover.underline.black-blue {
        color: #6084c2;
        opacity: 1;
    }
    .purchase-page .underline-link-btn:hover.underline.black-blue .btn-line-text {
        background-image: -webkit-gradient(linear, left top, left bottom, from(#6084c2), to(#6084c2));
        background-image: -o-linear-gradient(#6084c2, #6084c2);
        background-image: linear-gradient(#6084c2, #6084c2);
    }
    .purchase-page .underline-link-btn:hover .line:after {
        width: 0;
    }
    .purchase-page .underline-link-btn:hover .line:after, .purchase-page .underline-link-btn:hover .line:before {
        -webkit-transition: width 0.4s ease-out;
        -o-transition: width 0.4s ease-out;
        transition: width 0.4s ease-out;
    }
    .purchase-page .underline-link-btn:hover .line:before {
        -webkit-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s;
        width: 100%;
    }
}
@media only screen and (min-width: 720px) {
    .purchase-page .amount-row-inner {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
    }
}
.purchase-page .amount-price {
    display: block;
    font-size: 40px;
    margin-top: 15px;
}
@media only screen and (min-width: 720px) {
    .purchase-page .amount-price {
        display: inline-block;
        margin-top: 0;
    }
}
@media only screen and (min-width: 1025px) {
    .purchase-page .amount-price {
        font-size: 2.5vw;
    }
}
.purchase-page .payment-btns-container {
    margin-top: 30px;
}
@media only screen and (min-width: 1025px) {
    .purchase-page .payment-btns-container {
        margin-top: 3.13vw;
    }
}
.purchase-page .payment-btns-container {
    padding: 20px;
}
@media only screen and (min-width: 1025px) {
    /*Fixes and adjustments*/
    .purchase-page .payment-btns-container {
        padding-left: 3.39vw;
        padding-right: 3.39vw;
    }
}
.purchase-page .payment-btns-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.purchase-page a {
    cursor: pointer;
}
.purchase-page .header {
    background: rgba(255, 255, 255, 0.93);
}
@media only screen and (min-width: 1025px) {
    .purchase-page .news-letter-container {
        padding: 2.08vw 3.65vw;
    }
    .purchase-page .news-letter-text-item .title-wrap {
        margin-top: 0;
    }
}
@media only screen and (min-width: 720px) {
    /*signup form*/
    .purchase-page .news-letter-container {
        padding: 40px;
    }
}
.purchase-page .login-form {
    padding: 25px !important;
    font-size: 16px;
    position: relative;
    font-family: 'Roboto', sans-serif;
}
.purchase-page .login-form.authentication {
    height: 340px;
    position: relative;
    padding: 10px 20px 24px 20px;
}
.purchase-page .login-form.authentication .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}
.purchase-page .login-form.authentication .error-msg {
    color: red;
}
.purchase-page .login-form.authentication .success-msg {
    color: green;
}
.purchase-page .login-form.authentication .status-section {
    text-align: center;
}
.purchase-page .login-form.authentication .status-section h2 {
    line-height: 24px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    color: #666;
    position: relative;
    font-weight: 400;
    padding-top: 20px;
    font-size: 20px;
}
.purchase-page .login-form.authentication .status-section h2:before {
    position: absolute;
    left: -33px;
    top: 20px;
    width: 27px;
    height: 27px;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-size: 15px;
    line-height: 27px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: -3px 5px 0 0;
    font-weight: 900;
}
.purchase-page .login-form.authentication .status-section.blocked h2:before {
    content: "\f00d";
}
.purchase-page .login-form.authentication .status-section.blocked .reason {
    color: #666666;
    font-weight: bold;
    font-style: italic;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}
.purchase-page .login-form input {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding: 10px;
}
.purchase-page .login-form .signup-fields {
    position: relative;
}
.purchase-page .login-form .signup-fields .error-message {
    display: flex;
    position: relative;
    color: #C2272E;
    font-size: 12px;
    left: 0;
    line-height: 0;
    margin-left: 1px;
    top: -5px;
    width: 100%;
}
.purchase-page .login-form .signup-fields .general-error {
    color: #C2272E;
    font-size: 14px;
    margin-left: 1px;
    width: 369px;
}
.purchase-page .login-form .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 7px 0 26px;
}
.purchase-page .login-form .row .remember-me:hover {
    color: #7e98cc;
}
.purchase-page .login-form .btn {
    width: 100%;
    padding: 17px 20px;
}
.purchase-page .login-form input[type="checkbox"] {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
}
.purchase-page .login-form input[type="checkbox"]:checked + .fake-input:before {
    opacity: 1;
}
.purchase-page .login-form input[type="checkbox"] + .fake-input:before {
    position: absolute;
    content: "\e903";
    font-family: icomoon;
    font-weight: 700;
    color: #486db5;
    top: 47%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    font-size: 10px;
    width: 20px;
    text-align: center;
}
.purchase-page .login-form .fake-input {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #818181;
    background: #fff;
    border-radius: 4px;
    vertical-align: middle;
    position: relative;
    margin: 0 10px 3px 0;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 850px) {
    .purchase-page .login-form {
        font-size: 13px;
    }
    .purchase-page .login-form .signup-fields {
        width: 80vw;
        max-width: 98%;
    }
    .purchase-page .login-form .signup-fields .error-message {
        font-size: 9px;
        width: 100%;
        line-height: 1.3;
    }
    .purchase-page .login-form .signup-fields .general-error {
        font-size: 12px;
        width: 100%;
    }
    .purchase-page .login-form .signup-fields .users-number {
        font-size: 15px;
    }
}
.purchase-page fieldset {
    margin: 0;
    padding: 0;
    border: none;
    width: 100% !important;
}
.purchase-page .global-loader.hide {
    animation: hideFX 0.5s forwards;
    animation-delay: 0.5s;
}
@keyframes hideFX {
    0% {
        opacity: 1;
        visibility: visible;
    }
    99% {
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.purchase-page .commercial-section {
    z-index: 52;
    overflow: hidden;
    position: relative;
}
.purchase-page .commercial-section:after {
    position: absolute;
    content: "";
    background: url('../images/trustifiEnvelopDesign.png');
    height: 43%;
    width: 127%;
    top: 39%;
    opacity: 0.35;
    right: -20%;
    z-index: -1;
    background-size: cover;
}
.purchase-page .modal-info {
    position: absolute;
    background: white;
    border-radius: 10px;
    padding: 1.5vw;
    margin-top: -15px;
    transition: all 0.25s ease-out !important;
    z-index: 150;
    box-shadow: 0 3px 9px darkgrey;
}
.purchase-page .modal-container:hover .modal-info {
    margin-top: 0;
}
.purchase-page .purchase-step-container {
    /*animation: fadeInFX 0.15s forwards;*/
}
@keyframes fadeInFX {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.purchase-page .notification {
    height: 0;
    margin-left: -18px;
    margin-right: -18px;
    padding-left: 18px;
    font-family: 'Roboto Condensed', sans-serif;
}
.purchase-page .notification a {
    color: #419ee0 !important;
    cursor: pointer;
}
.purchase-page .notification-box {
    border: 1px solid #ccc;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-bottom: 14px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
}
.purchase-page notification-component {
    position: fixed;
    left: 300px;
    top: 20px;
    z-index: 2001;
    width: 60%;
}
.purchase-page .notification.error {
    background-color: #F5E8EA;
}
.purchase-page .notification.error > .notificationMessage {
    color: #9B202E;
}
.purchase-page .notification.error .notificationLink {
    cursor: pointer;
    color: #419ee0;
    text-decoration: underline;
}
.purchase-page .notification.error > .notificationIcon {
    color: #C2272E;
}
.purchase-page .notification.warning {
    background-color: #FDF2EA;
}
.purchase-page .notification.warning > .notificationIcon {
    color: #CC6633;
}
.purchase-page .notification.info {
    background-color: #E6F0EB;
}
.purchase-page .notification.info > .notificationIcon {
    color: #006837;
}
@media screen and (max-width: 470px) {
    .purchase-page notification-component {
        position: fixed !important;
        left: 25px !important;
        width: calc(100vw - 15px) !important;
        z-index: 2001 !important;
        margin: auto !important;
    }
}

