@media (max-width: 991px) {
	body {
		min-width: 100vw !important;
		--bs-dropdown-menu-max-height: 18dvh !important;
	}
	#main {
		overflow-x: hidden;
	}

	[class*="flxRow"], [class*="flex-row"] {
		flex-wrap: wrap;
	}

	.page-subtitle-container {
		flex-wrap: wrap;
		gap: 10px;

		.page-subtitle {
			flex: unset !important;
		}
	}

	.flxRow > .radio-container + div,
	.flex-row > .radio-container + div {
		flex: 1 0;
	}


	apx-chart {
		max-width: none !important;
		max-height: none !important;
	}

	.flex-row.gap-5 {
		gap: 1rem !important;
	}

	*[style*="width: max-content"] {
		width: auto !important;
	}

	*[style*="max-width: 17vw"], *[style*="max-width: 20vw"] {
		max-width: none !important;
	}

	country-code-select {
		input[style*="width: 45px"][style*="border-left: none"] {
			width: 45px !important;
			min-width: 0 !important;
		}
	}

	my-profile-component div[style*="height: 30px"] {
		height: auto !important;
	}

	div[style*="height: 265px"]:has(> div[style*="height: 125px"]) {
		height: auto !important;

		> div[style*="height: 125px"] {
			height: auto !important;
		}

		.flex-row[style*="margin-left: 300px"] {
			margin-left: 0 !important;
			justify-content: flex-end;
		}
	}

	.threat-tag, .app-dropdown-box, th > *,
	.app-header *, .sidebar-menu-item *,
	.app-content-area {
		flex-wrap: nowrap !important;
	}

	.app-content-container > div {
		padding: 0 4vw !important;
	}

	.flex-row:has(> *[class*="fa-info-circle text-info"] + span) {
		flex-wrap: nowrap !important;
		align-items: baseline !important;
	}

	.page-title-container {
		max-height: unset !important;
		gap: 5px;
		margin-bottom: 10px;
	}

	.app-container {
		height: 100dvh !important;


		input[type="text"],
		input[type="tel"],
		input[type="email"],
		input[type="search"],
		input[type="password"],
		app-dropdown,
		textarea {
			width: unset !important;
			min-width: 80px;
		}
	}

	*[style*="width: 33%"],
	.bs-col-6,
	reviewers-component .list-group.list-group-flush.settings-list-container > div.flex-column {
		width: 100% !important;
	}

	@for $i from 300 through 800 {
		*[style*="min-width: #{$i}px"] {
			min-width: 0 !important;
		}
		*[style*="flex-basis: #{$i}px"] {
			flex-basis: auto !important;
			flex-shrink: unset !important;
		}
		input[style*="width: #{$i}px"], app-dropdown-c[style*="width: #{$i}px"] {
			width: auto !important;
		}
	}

	// queries selection width
	*[style*="min-width: 460px"],
	*[style*="min-width: 485px"], {
		height: auto !important;
		min-height: 32px;

		*:has(svg) {
			display: none !important;
		}
	}

	threat-simulation-template-fields-component .div:has(> .simpleDataTable) {
		overflow: auto;

		> .simpleDataTable {
			table-layout: auto !important;
		}
	}

	threat-simulation-campaign-summary-component .trends-data-card {
		min-width: 100% !important;

		apx-chart {
			max-width: 110% !important;
		}
	}

	.threat-simulation-wizard-summary {
		*[style*="height: 16px"] {
			height: auto !important;
		}
	}

	.ts-wizard-template-screen {
		> * {
			min-width: 100% !important;
			max-width: none !important;
		}
	}

	.flex-row:has(> i.fas.fa-exclamation-circle + *:last-child),
	.flxRow:has(> i.fas.fa-exclamation-circle + *:last-child){
		flex-wrap: nowrap !important;
	}

	.flex-row:has(> i.fas.fa-circle + *:last-child),
	.flxRow:has(> i.fas.fa-circle + *:last-child) {
		flex-wrap: nowrap !important;
		align-items: baseline !important;
	}

	.flxRow[style*="flex-wrap: wrap-reverse"][style*="margin-bottom: 3px"] > .flxRow {
		flex: 1 0;
	}

	#wrapper {
		min-height: 100dvh !important;
	}

	.app-container .date-picker-container > input {
		padding-right: 30px;
		width: 100% !important;
		min-width: 190px;
	}

	.page-title {
		font-size: 1.1rem !important;
	}

	*[style*="max-width: 40%"]:has(.redborder[style*="position: absolute"]) {
		max-width: none !important;

		.redborder[style*="position: absolute"] {
			position: relative !important;
			right: auto !important;
			top: auto !important;
		}
	}

	.page-subtitle-container.flxRow-between.hSpace4 > * {
		margin: 0 !important;
	}

	popup-c {
		.popup-content-container {
			padding: 15px !important;
			max-height: unset !important;
		}

		.popup-title-container {
			padding-right: 45px;

			h5 {
				font-size: 0.8rem !important;
			}
		}
	}

	.table-filter {
		max-height: 40vh;
	}

	.info-banner {
		padding: 5px !important;

		&:after {
			content: "";
		}
	}

	integrations-component {
		popup-c .flex-column.f-1-0.overflow-auto.gap-4 > * > * {
			width: unset !important;
			flex: 1 0;
		}
	}

	.page-inner-content-holder {
		min-height: 0 !important;
	}

	.page-inner-content-holder span:has(+ more-info-c), {
		flex: 1 0;
		padding-right: 5px;
	}

	.flex-row > span:has(+ toggle-switch-c), .flxRow > span:has(+ toggle-switch-c) {
		flex: 1 0;
	}

	.flex-row:has(input[type="text"] ~ btn-c[endicon="fa fa-check"][style*="min-width: 0"]) {
		flex-wrap: nowrap;

		> * {
			width: auto !important;
		}
	}

	.flex-row:has(.fa.fa-copy.copy-link:last-child), .flxRow:has(.fa.fa-copy.copy-link:last-child) {
		flex-wrap: nowrap !important;
	}

	td .flex-row:has(.fa.fa-copy.copy-link:last-child), td .flxRow:has(.fa.fa-copy.copy-link:last-child) {
		flex-wrap: nowrap !important;
		justify-content: flex-end !important;
	}

	td .flxRow.hSpace1:has(.fa.fa-check-circle), td .flxRow:has(.fas.fa-check + app-dropdown-c[text="Actions"]) {
		flex-wrap: nowrap !important;
	}

	btn-c[endIcon="fas fa-file-download"] {
		display: none !important;
	}

	#appSidebarLogo {
		width: 175px !important;
		justify-content: unset;
		align-items: unset;

		img {
			position: relative;
			left: 6px;
		}
	}

	threat-prevention-component .content-container > div.bg-white.py-2,
	security-awareness-component .content-container > div.border.flex-column.gap-3 {
		max-width: 100%;
		overflow: auto;
	}

	inbound-allow-block-lists-component {
		.flex-column.gap-3:has(> .flex-column.gap-2) {
			max-width: 100%;
		}
	}

	div[style*="position: absolute"][style*="min-width: 537px"] {
		width: calc(100vw - 2rem) !important;
		min-width: unset !important;
		z-index: 10;
	}

	//more info button
	.domains-page-more-info .page-info-window,
	.page-info-window {
		max-width: 420px !important;
	}
	.page-info-window {
		left: unset;
		right: 0;
	}

	content-container {
		overflow: auto;
	}

	td .expandable-record-row {
		justify-content: flex-end !important;

		> div:first-child {
			flex: 1 0 !important;
		}
	}

	// header and sidebar
	.app-header {
		padding: 0.5rem 0;
		height: unset !important;

		.fs-6 {
			font-size: 12.5px !important;
		}

		.flex-row.h-100 {
			flex-wrap: wrap !important;
		}

		.flex-row div.align-items-center {
			display: flex !important;
		}

		.f-1-0:has(>.global-search) {
			display: none !important;
		}

		.app-page-title {
			flex-direction: column !important;
			flex: 1 0 50%;
			gap: 2px !important;
			height: 100%;
			justify-content: center !important;

			div.align-items-start {
				align-items: center !important;
			}
		}

		.btn-in-header {
			padding: 5px 7px !important;
			width: 25px !important;
			height: 25px !important;

			> i {
				font-size: 13px !important;
			}
		}

		#profileMenuContainer > div:not(.drop-menu) {
			height: 25px !important;
			width: 25px !important;
		}

		div.d-flex.align-items-center:has(>button) {
			display: none !important;
		}

		.flex-row-reverse {
			gap: 4px !important;
			margin: 0 !important;
			padding: 0 10px !important;

			> * {
				padding: 0 !important;
				gap: 4px !important;
			}

			button:has( > .fa-rocket) {
				display: none !important
			}
		}

		.profile-menu-container .drop-menu {
			top: calc(100% + 12px) !important;

			&:after {
				right: 65px !important;
			}
		}

		.support-menu-container .drop-menu:after {
			right: 115px !important;
		}
	}

	.md-tabs {
		overflow-x: auto;
		overflow-y: hidden;
		max-width: 100%;
		scrollbar-width: none;
		background: rgba(var(--bs-primary-rgb), 0.025);

		> *:not(.slide) {
			max-width: 40% !important;
			min-height: 35px;

			&.selected {
				background: rgba(var(--bs-primary-rgb), 0.2);
			}

			> label {
				max-width: 100% !important;
				height: 100%;
				padding: 7px;
			}
		}

		> .slide {
			display: none !important;
		}
	}

	.app-sidebar {
		position: absolute;
		height: 100%;
		max-height: 100dvh;
		top: 0;
		z-index: 9999;

		&.collapsed {
			max-width: 0 !important;
			border: none;
			overflow: hidden;
		}
	}
	.app-sidebar-toggle {
		transform: translateX(-10px) !important;
		box-shadow: none !important;
		background: none !important;
		top: 18px;
		border: none;


		.fa-angle-double-right {
			transform: none !important;

			&:before {
				content: "\f060" !important;
			}
		}
	}

	.dashboard-page > .page-inner-content-holder {
		.pe-2.py-1.rounded.gap-2.d-flex.align-items-center {
			max-width: 100% !important;
			scrollbar-width: none;
		}
	}

	.flxRow.hSpace2[style*="min-width: 605px"] {
		min-width: 100% !important;
		gap: 10px;
	}

	.content-container, .title-section-container {
		width: 100% !important;
		min-width: 100%;
		max-width: 100%;
		flex-wrap: wrap;

		.simpleDataTable {
			min-width: 720px;
		}
	}

	.settings-list-container {
		max-width: 100%;

		.settings-list-item {
			flex-wrap: wrap;
			padding: 28px min(15px, 3vw) 28px !important;

			> * + * {
				margin-top: 1rem;
			}
		}
	}

	.support-menu-container > .btn-in-header > span,
	.whats-new-notification-container,
	.threat-thumbnails-container > *:empty {
		display: none !important;
	}

	.threat-thumbnails-container {
		justify-content: space-around;
		width: 114%;
		margin-left: -7%;
	}

	.support-menu-container .drop-menu {
		right: -27px;
	}
	.profile-menu-container, .support-menu-container, .global-search, .admin-select, .trends-card-actions-menu, .plan-expiration-extend-menu, .whats-new-notification-container, .range-button, .compose-button-with-menu, .compose-contact-container {
		&:active {
			.drop-menu {
				visibility: visible;
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	inbound-mailboxes-component {
		search-box {
			width: unset !important;
			min-width: unset !important;
			flex-grow: 1;
		}
	}
	//trustifi-table's classes
	trustifi-table-c,
	inbound-quarantined-component,
	inbound-mailboxes-component {
		.inner-search-input {
			min-width: 0 !important;
		}

		.table-upper-row-container {
			gap: 15px;

			> .flxRow.hSpace05 {
				gap: 10px;
			}
		}

		virtual-scroller > .scrollable-content > table {
			min-width: max-content;
		}

		virtual-scroller > .scrollable-content > table > thead th,
		.listTable-c > table > thead th {
			width: 200px;
			min-width: 200px;

			&[style*="%"], &[style*="vw"]{
				width: 200px !important;
				min-width: 200px !important;;
			}
		}
	}

	.actions-column-header{
		width: 200px;
		min-width: 200px;

		&[style*="%"], &[style*="vw"]{
			width: 200px !important;
			min-width: 200px !important;;
		}
	}

	.listTable-c.ellips table span {
		white-space: normal;
		word-break: break-word;

		&[style*="max-width: min-content"] {
			word-break: unset;
		}
	}

	.table-actions-container {
		flex-basis: 100%;

		> .hide-when-empty {
			flex: 1 0;
		}
	}

	div:has(+ .storage-usage-chart) > * {
		font-size: 12px !important;
	}

	.flxRow:has(> .checkbox-button.radio-style + .checkbox-button.radio-style),
	.flex-row:has(> .checkbox-button.radio-style + .checkbox-button.radio-style) {
		flex-wrap: nowrap !important;
	}

	.listTable-c {
		&:not(.ellips) {
			span {
				white-space: inherit;
			}
		}

		th span {
			white-space: nowrap !important;
		}

		td {
			max-width: 150vw;
		}
	}

	.trustifi-table:not(.short-action-column) .listTable-c {
		tr > td:last-child app-dropdown-c[text="Actions"] {
			min-width: 160px;
		}
	}

	.trustifi-table[style*="flex: 0 0 300px"] {
		max-width: 100%;
	}

	// home page cards
	.inbound-trends,
	.inbound-trends2,
	.outbound-trends,
	.inbound-rules,
	.compromised-incidents,
	.mailboxes-location,
	.latest-audit-logs,
	.licenses-usage {
		width: 100% !important;
	}

	inbound-allow-block-lists-component .settings-list-container > .settings-list-item:first-of-type {
		height: unset !important;
	}
	inbound-allow-block-lists-component .settings-list-container > .settings-list-item:first-of-type > div:first-of-type {
		flex-wrap: wrap;
	}

	.note-toolbar {
		display: none !important;
	}

	templates-page-component {
		div:has(> .show-on-hover-item-1) {
			flex: 1 0;

			> .show-on-hover-item-1 {
				opacity: 1 !important;
				visibility: visible !important;
				margin-bottom: 15px;
			}
		}

		div[style*="flex-basis: 140px"]:empty {
			display: none !important;
		}

		#summernoteContainer {
			min-width: 100% !important;
		}
	}

	mass-list-page-component {
		label[style*="width: 250px"], label[style*="width:250px"] {
			width: 120px !important;
		}
	}

	partner-resources-component {
		.page-inner-content-holder > .w-50 {
			width: 100% !important;
		}
	}

	btn-c.popup-action-btn .simple-btn {
		height: 30px !important;
		min-width: 100% !important;
	}

	filter-table-c {
		min-width: min(310px, 85vw) !important;
	}

	apx-chart:has(.apexcharts-radialbar) {
		max-width: 70% !important;
		max-height: 70% !important;

		div:has(> .apexcharts-canvas), .apexcharts-canvas {
			max-width: 100% !important;
			max-height: 100% !important;
		}
	}

	compose-message-component {
		div[ngfdrop] {
			min-width: 100% !important;
		}

		div[style*="transition: max-height 0.35s ease-out"] {
			max-height: none !important;

			apx-chart {
				width: auto !important;
				height: auto !important;
				transform: none !important;
			}
		}
	}

	.fa.fa-exclamation-triangle + span {
		flex: 1 0;
	}


	div:has(> .directories-table) {
		overflow: unset !important;

		.directories-table {
			min-width: 100%;
			min-height: 45vh;
		}

		> div.vSpace3 {
			margin: auto;

			> div.vSpace2 {
				margin: auto;
			}
		}
	}

	div:has(btn-c[endicon="zmdi zmdi-check"] + btn-c[endicon="zmdi zmdi-close"]) {
		flex-wrap: nowrap !important;
	}


	partner-registration-component {
		div[style*="min-width: 40vw"] {
			min-width: 100% !important;
		}

		form .input-wrap {
			margin: 5px 0 !important;
		}

		*[style*="font-size: 1rem"] {
			font-size: 13px !important;
		}

		*[style*="font-size: 1rem"] {
			font-size: 13px !important;
		}

		span[style*="font-size: 1rem"][style*="cursor: pointer"] {
			font-size: 12px !important;
		}

		span[style*="flex-basis: 135px"] {
			flex-basis: 100px !important;
		}
	}

	.bs-enabled .login-box {
		margin: min(15px, 3vw) min(15px, 3vh) !important;
		height: calc(100dvh - min(30px, 6vh)) !important;
		width: calc(100vw - min(30px, 6vw)) !important;
		padding-top: 15vh !important;
		gap: 40px !important;

		.login-sso-button {
			width: 100% !important;
		}

		> * {
			max-height: fit-content;
			gap: 8px;
		}

		* {
			margin-top: 0 !important;
		}

		.text-nowrap {
			white-space: normal !important;
		}
	}

	.steps-container {
		font-size: 11px !important;
		flex-wrap: wrap !important;
		gap: 5px !important;

		.step-tab {
			padding-right: 1rem;
		}
	}

	more-info-c, btn-c:has(+ .page-info-window) {
		display: none;
	}

	.animated.fadeIn {
		animation: none !important;
	}

	.list-group {
		--bs-list-group-border-color: black !important;
	}

	.period-buttons-group {
		gap: .25rem !important;
		min-width: 150px;
		margin-bottom: 10px;

		.period-button, .range-button {
			margin: 0 !important;
		}

		.range-button {
			min-width: 232px !important;
		}
	}

	.flxRow:has(> div[style*="background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent)"]),
	.flex-row:has(> div[style*="background: linear-gradient(#163f5d1a, transparent, #a0a0a02e) , linear-gradient(74deg, #d3f1ff6b , transparent 51%, transparent)"]) {
		> div:first-child {
			min-width: 100% !important;
			height: 55vh;
		}

		> .vertical-separator {
			display: none !important;
		}

		> div:last-child {
			margin: 4px 0 !important;
			min-width: 100%;
			height: 55vh;
		}

		div[style*="calc(3px + 0.4vw)"] {
			flex-wrap: nowrap !important;

			search-box {
				width: auto !important;
			}
		}
	}

	search-box {
		min-width: 150px !important;
	}

	archive-email-details-component, sent-email-details-component {
		> .flxClmn {
			overflow: unset !important;
		}
	}

	archive-email-headers-component {
		th {
			width: auto !important;
			min-width: unset !important;
		}
	}

	.threat-protection-popup, outbound-quarantined-component popup-c.no-content-padding {
		.popup-content-container > div {
			overflow: auto !important;
		}

		.popup-frame-inner-container {
			width: 100% !important;
			height: 100% !important;
		}

		.tp-popup-header {
			position: sticky;
			top: 0;
			max-height: none !important;
			flex: unset !important;
			gap: 7px !important;

			+ div {
				flex-direction: column !important;
				flex-wrap: nowrap !important;
			}

			> * {
				gap: 7px !important;

				&:last-child > .flxRow.hSpace1:only-child {
					gap: 10px;
				}
			}

			* {
				font-size: 13px !important;
			}

			h3 {
				font-size: 1rem !important;
			}

			.vertical-separator {
				display: none !important;
			}

			.flxRow > .far.fa-clock + span {
				font-size: 12px !important;
			}

			.flxRow.ellipsis.hSpace2 {
				max-width: calc(100vw - 100px) !important;
				padding: 0 !important;
				flex-wrap: nowrap !important;
			}
		}

		div:has(> .tp-popup-sidebar) {
			overflow: unset !important;
		}

		.tp-popup-sidebar {
			width: 100% !important;
			min-height: fit-content !important;
			position: relative !important;

			&:after {
				content: "";
				position: absolute;
				background: #ffffff;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: -1;
			}
		}

		.threat-protection-grid {
			padding: 1.75vw !important;
		}

		.threat-protection-card {
			grid-column: span 24 !important;
			grid-row: span 24 !important;

			& + * {
				grid-column: span 24 !important;
				grid-row: span 24 !important;
			}

			.card-header {
				height: auto !important;

				.card-title {
					font-size: 14px !important;
				}
			}

			.flxRow:has(> .fas.fa-exclamation-triangle) {
				flex-wrap: nowrap !important;
			}
		}

	}

	*[style*="background: linear-gradient(0deg, #ffffff00, white), linear-gradient(90deg,white, #f7f7f778, white)"] {
		display: block !important;
	}

	account-compromised-incidents-component {
		*[style*="padding: 0 2vw"] {
			padding: 0 4vw !important;
		}

		*[style*="-bs-bg-opacity: 0.065"] {
			flex-wrap: nowrap !important;
		}

		.flxClmn.gap-1 > app-dropdown-c[text="Actions"] {
			margin-top: 15px !important;
		}
	}

	exchange-login-wizard-c {
		div[style*="max-width: 40%"] {
			max-width: none !important;
			width: 100% !important;
		}
	}

	span[style*="bottom: -17px"][style*="font-size: 11px"] {
		position: relative !important;
		bottom: auto !important;
	}

	new-rule-condition, new-rule-condition-inbound {
		app-dropdown-c + .redborder.fa {
			display: none !important;
		}
	}

	outbound-rule-usage-c, popup-c[head="Inbound Rule Usage Data"] {
		.vertical-separator {
			display: none !important;
		}
	}

	app-dropdown-c {
		.app-dropdown-menu-container .static-item[style*="position: sticky"] {
			z-index: 50;
		}
	}

	.flexRow-start.md-checkbox {
		display: flex;

		input[type="checkbox"] {
			height: 0;
		}
	}

	.popup-layer {
		padding: 5vh 5vw !important;
	}

	.popup-layer:has(.popup-frame-outer-container:not(.small):not(.dynamic)),
	.popup-layer:has(.popup-frame-outer-container.dynamic[style*="width"]),
	.popup-layer:has(.popup-frame-outer-container.dynamic[style*="height"]) {
		padding: 0 !important;
	}

	.popup-frame-outer-container:not(.small):not(.dynamic),
	.popup-frame-outer-container.dynamic[style*="width"],
	.popup-frame-outer-container.dynamic[style*="height"], {
		min-width: 100vw !important;
		min-height: 100dvh !important;
		width: 100vw !important;
		height: 100dvh !important;
	}

	look-and-feel-component {
		.page-inner-content-holder > div.flxRow.hSpace3 {
			> div.flxClmn {
				width: 100% !important;
				flex: unset !important;
			}

			.lf-settings-container {
				div[style*="min-height: 350px"] {
					div[style*="flex"] {
						flex: unset !important;
					}
				}

				.vertical-separator {
					display: none;
				}
			}
		}
	}

	account-compromised-incidents-component {
		.vertical-separator {
			display: none;
		}

		.page-inner-content-holder {
			div[style*="flex: 3 0"] {
				flex: unset !important;
				width: 100% !important;
				order: 1;
			}

			div[style*="position: sticky"] {
				flex: unset !important;
				position: relative;
				top: unset !important;
				order: 0;
			}
		}
	}

	threat-simulation-template-fields-component {
		span[style*="position: absolute"] {
			bottom: -30px !important;
		}
	}

	exchange-rules-wizard-c {
		.bs-btn-light[style*="min-width: 75px"] {
			min-width: 0 !important;
		}
	}
}

