@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon.eot?gp03mb");
	src: url("../fonts/icomoon.eot?gp03mb#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?gp03mb") format("truetype"), url("../fonts/icomoon.woff?gp03mb") format("woff"), url("../fonts/icomoon.svg?gp03mb#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
	input[type="button"] {
		-webkit-appearance: button;
		cursor: pointer;
		outline: 0;
	}
	input[disabled] {
		cursor: default;
	}
}
body {
	margin: 0;
	color: black;
	background: #fff;
	font: 13px/1.2 "Roboto", "Helvetica Neue", "Helvetica", sans-serif;
	min-width: 768px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
article {
	display: block;
}
aside {
	display: block;
}
details {
	display: block;
}
figcaption {
	display: block;
}
figure {
	display: block;
	margin: 1em 40px;
}
footer {
	display: block;
}
header {
	display: block;
}
hgroup {
	display: block;
}
main {
	display: block;
}
menu {
	display: block;
}
nav {
	display: block;
}
section {
	display: block;
}
summary {
	display: block;
}
audio {
	display: inline-block;
	vertical-align: baseline;
	&:not([controls]) {
		display: none;
		height: 0;
	}
}
canvas {
	display: inline-block;
	vertical-align: baseline;
}
progress {
	display: inline-block;
	vertical-align: baseline;
}
video {
	display: inline-block;
	vertical-align: baseline;
}
[hidden] {
	display: none;
}
template {
	display: none;
}
a {
	background-color: transparent;
	-webkit-transition: color .3s ease-in-out;
	transition: color .3s ease-in-out;
	color: black;
	text-decoration: none;
	&:active {
		outline: 0 !important;
		color: var(--bs-hover-color);
	}
	&:hover {
		outline: 0 !important;
		color: var(--bs-hover-color);
	}
	&:focus {
		color: #324c7e;
	}
}
abbr[title] {
	border-bottom: 1px dotted;
}
b {
	font-weight: bold;
}
strong {
	font-weight: bold;
}
dfn {
	font-style: italic;
}
h1 {
	margin: 0 0 6px;
	color: inherit;
	font-size: 23px;
	font-weight: 300;
	font-family: "Roboto Condensed", "Helvetica Neue", "Helvetica", sans-serif;
}
mark {
	background: #ff0;
	color: black;
}
small {
	font-size: 80%;
}
sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	bottom: -.25em;
}
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	top: -.5em;
}
img {
	border: 0;
	max-width: 100%;
	height: auto;
}
svg {
	&:not(:root) {
		overflow: hidden;
	}
}
hr {
	box-sizing: content-box;
	height: 0;
}
pre {
	overflow: auto;
	font-family: monospace, monospace;
	font-size: 1em;
}
code {
	font-family: monospace, monospace;
	font-size: 1em;
}
kbd {
	font-family: monospace, monospace;
	font-size: 1em;
}
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}
button {
	color: inherit;
	font: inherit;
	margin: 0;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: none;
	cursor: pointer;
	height: auto;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
input {
	color: inherit;
	font: inherit;
	margin: 0;
	line-height: normal;
	height: 30px;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
optgroup {
	color: inherit;
	font: inherit;
	margin: 0;
	font-weight: bold;
}
select {
	color: inherit;
	font: inherit;
	margin: 0;
	text-transform: none;
	-webkit-border-radius: 0;
	&:required {
		&:invalid {
			color: gray;
		}
	}
}
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
	overflow: auto;
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	margin-bottom: 12px;
	vertical-align: top;
	height: 188px;
	resize: none;
	padding: 9px 8px;
	&:focus {
		border-color: #324c7e;
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: #b3b3b3;
	}
	&::-moz-placeholder {
		opacity: 1;
		color: #b3b3b3;
	}
	&:-moz-placeholder {
		color: #b3b3b3;
	}
	&:-ms-input-placeholder {
		color: #b3b3b3;
	}
}
input[type="reset"] {
	-webkit-appearance: none;
	cursor: pointer;
	height: auto;
}
input[type="submit"] {
	-webkit-appearance: none;
	cursor: pointer;
	height: auto;
}
button[disabled] {
	cursor: default;
}
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}
input[type="number"] {
	border-radius: 4px;
	border: 1px solid #ccc;
	&::-webkit-inner-spin-button {
		height: auto;
	}
	&::-webkit-outer-spin-button {
		height: auto;
	}
}
input[type="search"] {
	-webkit-appearance: textfield;
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	padding: 2px 8px;
	margin-bottom: 12px;
	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
	}
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	&:focus {
		border-color: #324c7e;
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: #b3b3b3;
	}
	&::-moz-placeholder {
		opacity: 1;
		color: #b3b3b3;
	}
	&:-moz-placeholder {
		color: #b3b3b3;
	}
	&:-ms-input-placeholder {
		color: #b3b3b3;
	}
}
input.nofill {
	text-security: disc;
	-webkit-text-security: disc;
	-moz-text-security: disc;
	-ms-text-security: disc;
}
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0;
	padding: 0;
	border-style: none;
}
legend {
	border: 0;
	padding: 0;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td {
	padding: 0;
}
th {
	padding: 0;
}
[class^="icon-"] {
	font-family: "icomoon" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
[class*="icon-"] {
	font-family: "icomoon" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mail-send {
	&:before {
		content: "\e90b";
	}
}
.icon-refresh-button {
	&:before {
		content: "\e909";
	}
}
.icon-warning-sign {
	&:before {
		content: "\e90a";
	}
}
.icon-pen {
	&:before {
		content: "\e906";
	}
}
.icon-twitter {
	&:before {
		content: "\e901";
	}
}
.icon-facebook {
	&:before {
		content: "\e902";
	}
}
.icon-access {
	&:before {
		content: "\e907";
	}
}
.icon-check-mark {
	&:before {
		content: "\e908";
	}
}
.icon-google {
	&:before {
		content: "\e900";
	}
}

.page-title-container {
	flex: 0 0 85px;
	padding: 20px 0 0;
	max-height: 85px;

	.page-title-inner-container {
		align-items: center;
		flex: 1 0;
		// gap: 10px;
		.page-title {
			font-size: 1.3rem;
			font-weight: 500;
		}
	}
	.page-subtitle-container {
		flex: 1.5 0;
		justify-content: flex-start;
		align-items: flex-start;
		padding-top: 5px;
		> * {
			line-height: 1rem;
		}
	}
	.page-subtitle {
		padding-left: 0;
		margin: 0;
	}
	//.control-and-info-icons {
	//	align-self: flex-end;
	//	align-items: center;
	//	padding: 5px;
	//}
}
.page-subtitle {
	margin-bottom: 20px;
	overflow: hidden;
	padding-left: 20px;
	position: relative;
	background: linear-gradient(90deg, #f3f3f300, transparent 134%);
	display: inline-block;
}

.message-tab-content {
	.icon-list {
		margin: 0;
		padding: 0;
		list-style: none;
		letter-spacing: -.4em;
		padding-bottom: 39px;
		li {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			letter-spacing: normal;
			padding-right: 60px;
			&:last-child {
				i {
					&:after {
						display: none;
					}
				}
			}
		}
		li.not-active {
			i {
				color: #808080;
			}
		}
		li.active {
			&:before {
				content: "\f058";
				position: absolute;
				font-family: "Font Awesome 5 Free" !important;
				bottom: 5px;
				font-size: 10px;
				font-weight: 900;
			}
		}
		li.current {
			&:before {
				position: absolute;
				font-family: "Font Awesome 5 Free" !important;
				bottom: 5px;
				font-size: 10px;
				font-weight: 900;
				content: "\f057";
			}
		}
		i {
			min-height: 44px;
			line-height: 44px;
			display: block;
			position: relative;
			background: white;
			font-size: 35px;
			&:after {
				width: 80px;
				content: "";
				position: absolute;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				border: 1px dotted #c0c0c0;
			}
		}
		.icon-title {
			position: absolute;
			left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			bottom: -18px;
			font-family: "Roboto", "Helvetica Neue", "Helvetica", sans-serif !important;
			font-size: 13px;
		}
	}
	.row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 10px 0 3px !important;
	}
	.row.alt {
		display: block;
	}
	.label-wrap {
		max-width: 130px;
		color: black;
		width: 100%;
		padding-right: 15px;
	}
	.txt-wrap {
		position: relative;
		.with-icon {
			position: relative;
			display: inline-block;
			letter-spacing: -1px;
		}
		.tooltip-box {
			display: block;
			position: absolute;
			top: -9px;
			left: 40px;
			border: 1px solid #b2b2b2;
			background: #fff;
			box-shadow: 0 0 2px 0 rgba(0, 0, 0, .75);
			font-size: 13px;
			padding: 11px 14px 11px 8px;
			min-width: 245px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			&:before {
				content: "";
				position: absolute;
				top: 50%;
				right: 100%;
				margin: 0 1px 0 0;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				border-style: solid;
				border-width: 7px 7px 7px 0;
				border-color: transparent #b2b2b2 transparent transparent;
			}
			&:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 100%;
				margin: 0 -1px 0 0;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				border-style: solid;
				border-width: 7px 7px 7px 0;
				border-color: transparent #fff transparent transparent;
			}
			span {
				padding: 0 15px 0 0;
				&:last-of-type {
					padding: 0;
				}
			}
		}
	}
	.attach-number {
		margin-bottom: 17px;
	}
	.attached-file {
		margin-bottom: 6px;
		img {
			display: inline-block;
			vertical-align: middle;
			width: 27px;
			height: 22px;
			margin-right: 7px;
		}
	}
	.txt-block {
		margin-bottom: 6px;
	}
	.description-popup {
		display: inline-block;
		vertical-align: middle;
	}
	.select-wrap {
		max-width: 181px;
		margin-bottom: 28px;
		select {
			color: #000;
		}
	}
	.filter-box {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		margin: 0 0 15px;
		a {
			display: inline-block;
			vertical-align: top;
			padding: 0 15px 0 0;
		}
		&:after {
			position: absolute;
			right: 5px;
			content: "\f2f2";
			font-size: 16px;
			font-family: "Material-Design-Iconic-Font";
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			z-index: 1;
			color: #818181;
		}
	}
	.slide {
		padding: 0 15px;
		margin-bottom: 8px;
		border: 1px solid #edeeef;
		.box {
			padding: 11px 11px 6px 11px;
			border-top: 2px solid #edeeef;
			word-break: break-word;
			&:first-child {
				border: none;
			}
		}
		label {
			display: block;
			margin-bottom: 7px;
		}
	}
	.event-and-recipients {
		&:after {
			content: "";
			display: block;
			width: 400px;
			border-bottom: 2px dotted #edeeef;
		}
	}
	.event-and-recipients-margin {
		&:after {
			padding-top: 10px;
		}
	}
	.row-holder {
		padding: 6px 15px 5px 12px;
		border-bottom: 2px dotted #edeeef;
		max-width: 400px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		color: black;
	}
	.summary-recipients {
		max-width: 90%;
		display: flex;
		padding-left: 23px;
	}
}
.btn-list {
	margin: 0;
	padding: 0;
	list-style: none;
	padding-top: 5px;
	display: inline-block;
	&.not-edit {
		float: right;
	}
	li {
		display: inline-block;
		vertical-align: top;
		margin-right: 8px;
	}
	a {
		font-size: 16px;
		color: #818181;
	}
}

.attachment-list {
	margin: 0;
	padding: 0;
	list-style: none;
	padding-bottom: 4px;
	li {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding-bottom: 9px;
	}
	li.finished {
		.item {
			.after {
				background: #009245;
				width: 100%;
			}
		}
	}
	.close {
		margin-left: 14px;
		color: #818181;
		font-size: 16px;
		&:hover {
			color: var(--bs-hover-color) !important;
		}
	}
	.item {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		background: #f5f5f5;
		width: 240px;
		padding: 12px 11px;
		.after {
			position: absolute;
			content: "";
			bottom: 0;
			left: 0;
			width: 45%;
			height: 3px;
			background: #5fc0e9;
		}
	}
}

.attachment-list-item-after {
	position: absolute;
	content: "";
	bottom: 0;
	left: 0;
	width: 45%;
	height: 3px;
	background: #5fc0e9;
}

.popup-list {
	margin: 0;
	padding: 0;
	list-style: none;
	a {
		-webkit-transition: color .3s ease-in-out, background .3s ease-in-out;
		transition: color .3s ease-in-out, background .3s ease-in-out;
		display: block;
		padding: 4px 12px;
		color: black;
		&:hover {
			background: rgba(204, 204, 204, 0.3) !important;
			color: var(--bs-hover-color);
		}
	}
}
.admin-panel-row {
	margin-bottom: 0;
	padding: 0;
	display: flex;
	width: 95%;
	align-items: baseline;
	position: relative;

	.message-note {
		position: absolute;
		right: 50px;
		top: -16px;
		color: grey;
		font-size: 13px;
	}
	label {
		margin-right: 10px;
	}
}
.plan-allocation-label {
	margin-right: 10px;
}

.app-sidebar {
	position: relative;
    // primary color but fixed lightness to avoid too dark sidebar
    background: hsl(var(--bs-primary-h),var(--bs-primary-s),70%);
	z-index: 900;
	width: 225px;
	max-width: 225px; // for collapse animation
	border-right: solid 2px lightgrey;
	transition: max-width 0.15s ease-out, margin 0.15s ease-out;

    // background upper layer
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.95);
    }

    nav {
        overflow: auto;
    }

	.sidebar-menu-item {
        position: relative;
        min-height: 48px; // fixed height for all collapsed items (even those with word wraps)
		max-height: 48px; // keeping collapsed items collapsed until they are clicked
        transition: max-height 0.15s;


        .sidebar-menu-title {
            cursor: pointer;
            min-height: 0; // for animation when collapsed
            transition: min-height 0.15s;
            flex-shrink: 0;


            span, i:not(.section-icon) {
                transition: all 0.15s 0.15s;
            }

            .section-icon {
                width: 27px;
                font-size: 13px;
                transition: all 0.15s;

                &.zmdi-shield-security{
                    transform: scale(1.2);
                    transform-origin: left center;
                }
                &.fa-user-secret {
                    transform: scale(1.05);
                    transform-origin: left center;
                }

				&.image-icon {
					height: 17px;
					mask-size: auto 17px;
					-webkit-mask-size: auto 17px;
					mask-repeat: no-repeat;
					-webkit-mask-repeat: no-repeat;
					transition: all 0.15s;
					margin-left: -2px;
					margin-right: 2px;
				}
            }

            &:hover {
                background: #00000005;
            }
        }

		.sidebar-submenu-container {
			background: transparent;
            font-weight: 500;
			transition: max-height 0.15s, margin 0.15s;
			max-height: 0;
			overflow: hidden;
			margin: 0;
            margin-left: 18px;
			border-left: solid 3px #ededed;

            .sidebar-submenu-dropdown-title {
                display: none;
                .fa-chevron-down {
                    transform : scale(1.3,1) rotate(90deg);
                }
            }

			.sidebar-submenu-item {
                color: var(--bs-dark);
				border-radius: 5px;
				padding: 7px 10px;

				&:hover {
					background: #00000005;
				}
				&.active {
                    // increase saturation by 35%, limit lightness to 55%
					color: hsl(var(--bs-primary-h), calc((100% - var(--bs-primary-s))/2 + var(--bs-primary-s)), Min(var(--bs-primary-l), 55%));
					font-weight: bold;
					background: hsla(var(--bs-primary-h), calc(var(--bs-primary-s) + 30%), var(--bs-primary-l),0.05);
				}
			}
		}

		&.expanded {
			max-height: 360px;
			.sidebar-submenu-container {
				max-height: 300px;
				padding-bottom: 10px;
				// border-bottom: solid 1px whitesmoke;
				margin: 5px 0 10px 18px;
			}
            .sidebar-menu-title {
                .fa-chevron-down {
                    transform : scale(1.3,1) !important;
                }
            }
		}

        &.no-sub-items {
            .sidebar-submenu-container {
                display: none !important;
            }
        }

        &.active {
            .sidebar-menu-title > *:not(.fa-chevron-down) {
                color: hsl(var(--bs-primary-h),calc(var(--bs-primary-s) + 30%),var(--bs-primary-l));
            }
			.image-icon{
				background: hsl(var(--bs-primary-h),calc(var(--bs-primary-s) + 30%),var(--bs-primary-l)) !important;
			}
        }
	}

    > *:not(.app-sidebar-toggle) {
        width: 220px;
    }

    #appSidebarLogo {
        transition: all 0.15s 0.15s;
        > img {
            max-width: 65%;
        }
    }

	a {
		text-decoration: none !important;
	 }

    .sidebar-footer {
        font-size: 11px;
        transition: all 0.15s 0.15s;
        .footer-links {
            column-gap: 12px;
        }
    }
}

.app-sidebar.collapsed {
	max-width: 50px;

    nav {
        overflow: visible;
    }

    > *:not(.app-sidebar-toggle) {
        width: 100%;
    }

	.app-sidebar-toggle .fa-angle-double-right {
		transform: rotate(0deg);
	}
	#appSidebarLogo {
        visibility: hidden;
        transition: all 0.03s 1ms;
		opacity: 0;
        > img {
            visibility: hidden;
        }
	}
    .sidebar-footer {
        transition: all 0.03s 1ms;
        opacity: 0;
    }
	.sidebar-menu-title {
        min-height: 48px;
        span, i:not(.section-icon) {
            transition: all 0.03s 1ms;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
        }
        .section-icon {
            font-size: 15px;

			&.image-icon {
				height: 20px;
				mask-size: auto 20px;
				-webkit-mask-size: auto 20px;
			}
        }
	}
    .sidebar-menu-item {
        overflow: hidden;

        &:hover {
            overflow: visible;
            .sidebar-menu-title {
                background: #f9f9f9;
            }
            .sidebar-submenu-container {
                display: flex !important;
                visibility: visible !important;
                opacity: 1 !important;
                transition: all 0.1s 1ms;
                max-height: none;
                background: white;
                -webkit-appearance: none;
                -webkit-box-shadow: 2px 0 5px grey;
                box-shadow: 2px 0 5px grey;
                margin: 0;
                padding: 0;
                // avoid hovering gap between drop-menu and triggering icon
                &:before {
                    content: "";
                    left: -3px;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    width: 3px;
                }
            }
        }
        &.active {
            .section-icon {
                color: hsl(var(--bs-primary-h),calc(var(--bs-primary-s) + 30%),var(--bs-primary-l));
            }
			.image-icon {
				background: hsl(var(--bs-primary-h),calc(var(--bs-primary-s) + 30%),var(--bs-primary-l)) !important;
			}
        }
        &.no-sub-items {
            .sidebar-submenu-container {
                display: block !important;
                width: fit-content;
                transition: all 1ms;
            }
        }
    }
    .sidebar-submenu-container {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
        position: absolute;
        top: 0;
        right: -2px;
        transform: translateX(100%) translate3d(0,0,0);
        margin: 0;
        width: 160px;
        min-width: fit-content;
        border: none;
        padding: 0 0 5px;
        max-height: none;
        overflow: visible;

        .sidebar-submenu-item {
            margin: 0 !important;
            border-radius: 0;
            padding: 8px 15px;
            gap: 8px; // for the "new" tag's spacing
            white-space: nowrap;

            &:hover {
                background: #f5f5f5;
            }
            &.active {
                border-left: solid 3px hsl(var(--bs-primary-h), calc((100% - var(--bs-primary-s))/2 + var(--bs-primary-s)), Min(var(--bs-primary-l), 55%));
            }
        }
        .sidebar-submenu-dropdown-title {
            display: block;
            cursor: default;
            background: #f9f9f9;
            visibility: visible;
            flex-basis: 36px;
            flex-shrink: 0;
            margin-left: -3px;
            z-index: 60;
            padding: 11px;
            padding-left: 7px;
            font-weight: bold;
            color: dimgrey;
            letter-spacing: 0.1px;
        }

        &:hover {
            z-index: 1200;
        }
    }

    @media screen and (max-height: 650px) {
        .sidebar-menu-item:nth-child(n+7) {
            .sidebar-submenu-container {
                transform: translate(100%, -100%);
                top: 48px;
            }
        }

        .section-icon {
            font-size: 13px;
        }
    }
}

.app-sidebar-toggle {
	position: absolute;
	cursor: pointer;
	top: 30px;
	right: 0;
	transform: translateX(50%);
	height: 40px;
	width: 35px;
	background: white;
	z-index: 300;
	border: solid 1px #f3f3f3;
	box-shadow: 0 0 3px #b9b9b9;
	border-radius: 11px;
	transition: all 0.15s ease-out;

	&:hover {
		background: #f3f3f3;
		box-shadow: none;
		border-color: #cccccc;
		.fa-angle-double-right {
			color: black !important;
		}
	}

	.fa-angle-double-right {
		color: #707070;
		font-size: 18px;
		transform: rotate(180deg);
		transition: transform 0.075s 0.15s ease-out, color 0.1s;
	}
}

.recipient-wrap {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
	position: relative;
	margin-bottom: 12px;
	text-align: left;
	.ready-recipient {
		display: inline-block;
		vertical-align: middle;
		color: #000;
		min-height: 30px;
		margin-right: 8px;
		max-width: 325px;
		&:only-child {
			width: max-content;
		}
	}
	.ready-recipient.compose {
		line-height: 25px;
	}
	.input-wrap {
		position: relative;
		&:not(:first-child) {
			display: none;
		}
		span {
			position: absolute;
			content: "\f278";
			font-family: "Material-Design-Iconic-Font";
			top: 6px;
			right: 10px;
			color: #818181;
			font-size: 16px;
			font-weight: 700;
		}
	}
	input {
		display: inline-block;
		margin-bottom: 0;
	}
}
.recipient-wrap.success {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
	margin-bottom: 6px;
	.input-wrap {
		width: 50%;
		&:first-child {
			margin-bottom: 9px;
		}
		&:not(:first-child) {
			display: block;
		}
		&:before {
			display: none;
		}
		input {
			margin-right: 10px;
			width: 73%;
			&:only-child {
				width: 96%;
			}
		}
	}
	.input-wrap.lg {
		width: 100%;
	}
	.input-wrap.md {
		width: 31%;
	}
	.input-wrap.sm {
		width: 19%;
	}
	.phone {
		display: block;
	}
}

.attachment-row {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
	-webkit-transition: background .3s ease-in-out, border-color .3s ease-in-out;
	transition: background .3s ease-in-out, border-color .3s ease-in-out;
	background: #f5f5f5;
	border: 1px dotted #e2e3e4;
	padding: 20px 16px 18px 12px;
	cursor: pointer;
	.attachment-popup {
		float: left;
	}
	.title {
		-webkit-transition: color .3s ease-in-out;
		transition: color .3s ease-in-out;
		float: right;
	}
}
.attachment-row-alt {
	-webkit-transition: background .3s ease-in-out, border-color .3s ease-in-out;
	transition: background .3s ease-in-out, border-color .3s ease-in-out;
	background: #f5f5f5;
	border: 1px dotted #e2e3e4;
	padding: 12px;
	cursor: pointer;
	.title {
		-webkit-transition: color .3s ease-in-out;
		transition: color .3s ease-in-out;
	}
}
.form {
	.account-wrap {
		&:after {
			content: "";
			display: block;
			clear: both;
		}
		.input-wrap {
			.phone-number-input {
				width: calc(100% - 94px);
			}
		}
		.description {
			display: block;
			font-size: 11px;
			color: #818181;
			padding-bottom: 3px;
		}
		.input-wrap.md {
			width: 77%;
			float: left;
			padding: 0 0 0 15px;
		}
		.input-wrap.sm {
			width: 23%;
			float: left;
			padding: 0 0 0 0;
			select {
				padding: 2px 15px 2px 26px;
			}
		}
	}
	.label-col {
		display: inline-block;
		position: relative;
		padding-right: 15px;
		width: 350px;
	}
	.label-col-btns {
		display: inline-block;
		position: relative;
		padding-right: 15px;
	}
	label {
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin-bottom: 15px;
		color: black;
		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}
	.col {
		/*display: none;*/
		vertical-align: top;
		width: 60%;
	}
	.col.big-col {
		width: 100%;
		.fake-select {
			margin-bottom: 13px;
		}
	}
	.col.danger-zone {
		border: 1px solid #c2272e;
		min-width: 493px;
		padding: 20px;
		font-size: 18px;
		color: #c2272e;
		.btn {
			display: block;
			margin-top: 10px;
			background: #c2272e;
			&:hover {
				background: #da272e !important;
			}
		}
	}
	.col.col-md {
		width: 44%;
	}
	.col.col-lg {
		width: 824px;
		.row {
			padding: 0 0 17px;
			border: none;
		}
		.select-wrap {
			width: 312px;
		}
	}
	.col.plan-block {
		.ready-info-wrap {
			padding-bottom: 5px;
		}
		.slide-chekbox {
			width: 32px;
			height: 18px;
			input {
				height: 15px;
				&:checked {
					+ {
						span {
							left: 14px;
							background: #324c7e;
						}
					}
				}
			}
			span {
				width: 15px;
				height: 15px;
				top: 1px;
			}
		}
		.checkbox-block {
			margin: 12px 0 12px;
			.holder {
				margin-bottom: 0;
			}
			label {
				color: #000;
			}
		}
		.input-wrap {
			width: 308px;
		}
		.input-wrap.alt {
			padding: 0 0 23px;
		}
		.input-wrap.md {
			width: 138px;
			padding: 0;
		}
	}
	.row {
		border-bottom: 1px dotted #f5f5f5;
		padding: 19px 0;
		.title {
			font-size: 18px;
			margin-bottom: 0;
		}
		.danger-icon {
			left: 200px;
			top: 30px;
			&:before {
				color: #e84634;
			}
		}
		.danger-txt {
			top: 29px;
			left: 220px;
		}
	}
	.row.disable {
		opacity: 0.5;
		pointer-events: none;
	}
	.row.active {
		padding: 22px 0 11px;
		border-bottom-style: solid;
		.label-col {
			padding-top: 9px;
		}
		label {
			color: #000;
		}
		.col {
			display: inline-block;
		}
	}
	.row.ready {
		padding: 28px 0 22px;
		border-bottom-style: solid;
		.col {
			display: inline-block;
		}
		.label-col {
			label {
				color: #000;
			}
		}
	}
	.continue-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 100%;
		padding: 12px 0;
		.btn {
			display: block;
			width: auto;
			min-width: 100px;
		}
	}
	.ready-info-wrap {
		.title {
			display: inline-block;
			margin: 0 0 5px;
		}
		.box-with-danger {
			padding-top: 20px;
			.danger-txt {
				width: auto;
				padding-left: 6px;
				position: relative;
				display: inline-block;
				vertical-align: top;
				top: 0;
				left: 0;
			}
			.danger-icon {
				position: relative;
				display: inline-block;
				vertical-align: top;
				top: 0;
				left: 0;
			}
		}
	}
	.input-info {
		display: block;
		margin: 0 0 12px;
		color: #808080;
	}
	.input-wrap {
		position: relative;
		input {
			width: 100%;
		}
		input.short {
			width: 50%;
		}
	}
	.input-wrap.admin-panel-input {
		padding: 12px 0;
	}
	.input-wrap.error {
		input {
			border-color: #e84634;
			color: #e84634;
		}
		.tooltip {
			display: block;
			color: #e84634;
		}
	}
	.input-wrap.active {
		.tooltip {
			display: block;
		}
	}
	.tooltip {
		top: 3px;
	}
	.btn {
		font-size: 13px;
	}
	.select-wrap {
		select {
			color: #000;
		}
	}
	.select-wrap.short {
		width: 308px;
	}
	.check-row {
		font-size: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-bottom: 0;
	}
	.check-item {
		position: relative;
		width: 32.5%;
		font-size: 13px;
		label {
			width: 100%;
			margin-bottom: 0;
		}
		.fake-label {
			width: 100%;
			margin-bottom: 0;
		}
		.description-popup {
			position: absolute;
			top: 12px;
			right: 6px;
			display: none;
		}
		.description {
			display: block;
			color: #818181;
			font-size: 11px;
			margin: 0 10px 5px 0;
		}
		.price {
			display: block;
			font-size: 11px;
			color: #000;
			.big {
				font-size: 13px;
				font-weight: 700;
			}
		}
	}
	.checked {
		.fake-label {
			background: #e2e3e4;
		}
		.description-popup {
			display: block;
		}
	}
	.fake-input {
		position: absolute;
		top: 8px;
		left: 6px;
		background-color: transparent;
	}
	.fake-label {
		-webkit-transition: background .3s ease-in-out, box-shadow .3s ease-in-out;
		transition: background .3s ease-in-out, box-shadow .3s ease-in-out;
		display: inline-block;
		border-radius: 4px;
		border: 1px solid #e2e3e4;
		padding: 11px 10px 7px 30px;
		&:hover {
			background: #e2e3e4 !important;
			box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, .35) !important;
		}
	}
	.title {
		display: block;
		color: #000;
		margin-bottom: 9px;
		font-family: "Roboto Condensed", "Helvetica Neue", "Helvetica", sans-serif;
	}
}

.page-info-window {
    width: clamp(625px, 58vw, 1000px);
    top: 0;
    left: 25px;
    padding: 0 0 19px 0;
    border: solid 3px lightgrey;
    border-radius: 7px;
	max-width: 1100px;
	min-height: 12.5vw;
	max-height: calc(100vh - 200px);
	font-size: 0.75rem;
	position: absolute;
	z-index: 1031;
	filter: grayscale(50%);
	overflow-x: hidden;
	overflow-y: auto;
	background: white;

	margin-top: -10px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s;

	> div:not(.title-row) {opacity: 0;}

	&.shown {
		opacity: 1;
		visibility: visible;
		margin-top: -2; // the question mark button is 2px off the top
		> div:not(.title-row) {
			-webkit-animation: TTfadein 0.2s forwards;
			-moz-animation: TTfadein 0.2s forwards;
			-ms-animation: TTfadein 0.2s forwards;
			-o-animation: TTfadein 0.2s forwards;
			animation: TTfadein 0.2s forwards;
			animation-delay: 0.2s;
		}
	}

	.title-row {
        padding: 5px;
        padding-left: 15px;
		margin-bottom: 15px;
		align-items: center;
		font-style: italic;
		font-size: 0.8rem;
		background: linear-gradient(45deg, #e7e7e7, #f9f9f9);
	}
	.content-wrapper {
		margin: 0 1.5vw;
		font-size: 0.8rem;
		line-height: 1.2rem;
	}

	.guide-link-wrapper {
		align-self: flex-end;
		font-weight: 500;
		padding: 10px 20px;
		margin-top: 40px;
		margin-right: 15px;
		background: linear-gradient(45deg, #F9F9F9, transparent, #F9F9F9);
		border: dotted 2px #e3e4e4;
		border-left: none;
		border-right: none;
		font-size: 0.8rem;
	}

}

* {
	box-sizing: inherit;
	max-height: 1000000px;
	&:before {
		box-sizing: inherit;
	}
	&:after {
		box-sizing: inherit;
	}
}

.h1 {
	margin: 0 0 6px;
	color: inherit;
	font-size: 23px;
	font-weight: 300;
	font-family: "Roboto Condensed", "Helvetica Neue", "Helvetica", sans-serif;
}
h2 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 27px;
}
.h2 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 27px;
}
h3 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 24px;
}
.h3 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 24px;
}
h4 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 21px;
}
.h4 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 21px;
}
h5 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 17px;
}
.h5 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 17px;
}
h6 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 15px;
}
.h6 {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
	font-size: 15px;
}
.h {
	font-family: inherit;
	font-weight: bold;
	margin: 0 0 6px;
	color: inherit;
}
p {
	margin: 0 0 1em;
}
a.disabled {
	color: #818181;
	tab-index: -1;
	cursor: not-allowed;
	pointer-events: none;
}
.disabled {
	a {
		color: #818181;
		tab-index: -1;
		cursor: not-allowed;
		pointer-events: none;
	}
	.btn {
		cursor: not-allowed;
		background: #b3b3b3;
		&:hover {
			box-shadow: none !important;
			background-color: #b3b3b3;
		}
	}
	>.holder {
		>:not(.slide-chekbox) {
			input {
				opacity: 0.5 !important;
				pointer-events: none;
				cursor: not-allowed;
			}
		}
		>input {
			opacity: 0.5 !important;
			pointer-events: none;
			cursor: not-allowed;
		}
	}
	label {
		opacity: 0.5 !important;
		pointer-events: none;
		cursor: not-allowed;
	}
	.dropdown, app-dropdown , app-dropdown-c {
		opacity: 0.5 !important;
		pointer-events: none;
		cursor: not-allowed;
	}
}
form {
	margin: 0;
	padding: 0;
	border-style: none;
}
input[type="text"] {
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	padding: 2px 8px;
	margin-bottom: 12px;
	&:focus {
		border-color: #324c7e;
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: #b3b3b3;
	}
	&::-moz-placeholder {
		opacity: 1;
		color: #b3b3b3;
	}
	&:-moz-placeholder {
		color: #b3b3b3;
	}
	&:-ms-input-placeholder {
		color: #b3b3b3;
	}
}
input[type="tel"] {
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	padding: 2px 8px;
	margin-bottom: 12px;
	&:focus {
		border-color: #324c7e;
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: #b3b3b3;
	}
	&::-moz-placeholder {
		opacity: 1;
		color: #b3b3b3;
	}
	&:-moz-placeholder {
		color: #b3b3b3;
	}
	&:-ms-input-placeholder {
		color: #b3b3b3;
	}
}
input[type="email"], .emailType {
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	padding: 2px 8px;
	margin-bottom: 12px;
	&:focus {
		border-color: #324c7e;
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: #b3b3b3;
	}
	&::-moz-placeholder {
		opacity: 1;
		color: #b3b3b3;
	}
	&:-moz-placeholder {
		color: #b3b3b3;
	}
	&:-ms-input-placeholder {
		color: #b3b3b3;
	}
}
input[type="password"] {
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	padding: 2px 8px;
	margin-bottom: 12px;
	&:focus {
		border-color: #324c7e;
		outline: none;
	}
	&::-webkit-input-placeholder {
		color: #b3b3b3;
	}
	&::-moz-placeholder {
		opacity: 1;
		color: #b3b3b3;
	}
	&:-moz-placeholder {
		color: #b3b3b3;
	}
	&:-ms-input-placeholder {
		color: #b3b3b3;
	}
}
input[type="text"].placeholder {
	color: #b3b3b3;
}
input[type="tel"].placeholder {
	color: #b3b3b3;
}
input[type="email"].placeholder {
	color: #b3b3b3;
}
input[type="search"].placeholder {
	color: #b3b3b3;
}
input[type="password"].placeholder {
	color: #b3b3b3;
}
textarea.placeholder {
	color: #b3b3b3;
}
input[type="button"] {
	-webkit-appearance: none;
	cursor: pointer;
	height: auto;
}
input[type="file"] {
	-webkit-appearance: none;
	cursor: pointer;
	height: auto;
}
.readonly-input {
	background: #f9f9f9;
	pointer-events: none;
}
#wrapper {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	//padding-bottom: 40px;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.container {
	padding: 0 18px;
	margin: 0 auto;
	width: 100%;
	height: 100%;
}
.disabled.holder {
	>input {
		opacity: 0.5 !important;
		pointer-events: none;
		cursor: not-allowed;
	}
}

/* The container */
.radio-container {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/* Hide the browser's default radio button */
	input {
		position: absolute;
		left: 0;
		opacity: 0;
		cursor: pointer;
	}
	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 15px;
		width: 15px;
		border-radius: 50%;
		border: 1px solid;
	}

	&:hover input ~ .checkmark {
		background-color: #ccc;
	}
	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border: solid 2px white;
		border-radius: 50%;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	input:checked ~ .checkmark:after {
		display: block;
	}
}


i.disabled {
	cursor: not-allowed;
	&:hover {
		cursor: not-allowed;
	}
}
#main {
	//margin-left: 70px;
	overflow-x: visible;
}
@media screen and (max-width: 850px) {
	#main {
		margin-left: 0;
	}
}

.message-form {
	.form-content {
		border-bottom: 1px solid #f5f5f5;
	}
	.label-col {
		position: relative;
		padding: 9px 15px 0 0;
		label {
			display: inline-block;
			margin-bottom: 15px;
		}
		.icon {
			display: inline-block;
		}
	}
	.label-col.alt {
		padding: 0 15px 0 0;
		i {
			-webkit-transform: rotate(-180deg);
			-ms-transform: rotate(-180deg);
			transform: rotate(-180deg);
			margin: 0 0 0 25px;
		}
	}
	.col {
		display: inline-block;
		vertical-align: top;
		width: 60%;
		input {
			width: 100%;
		}
		textarea {
			width: 100%;
		}
	}
	.col.right {
		text-align: right;
	}
	.link-select {
		float: right;
		position: absolute;
		top: 0;
		left: 420px;
		&:before {
			content: "\f20d";
			font-family: "Material-Design-Iconic-Font";
			font-size: 16px;
			padding: 0 7px 0 3px;
			position: relative;
			top: 1px;
		}
	}
	.content-popup {
		.opener {
			top: 6px;
		}
	}
}
.add-user-form {
	.link-select {
		&:before {
			content: "\f20d";
			font-family: "Material-Design-Iconic-Font";
			font-size: 16px;
			padding: 0 7px 0 3px;
		}
	}
}

.flex-container {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-box-direction: normal;
	-moz-box-direction: normal;
	-webkit-box-orient: horizontal;
	-moz-box-orient: horizontal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: start;
	-moz-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	-webkit-box-align: start;
	-moz-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.flex-item {
	-webkit-box-ordinal-group: 1;
	-moz-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-box-flex: 0;
	-moz-box-flex: 0;
	-webkit-flex: 0 1 auto;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	align-self: auto;
}
.actionRow {
	border-bottom: 1px solid #edeeef;
	padding-bottom: 5px;
	width: 100%;
	.actionLabel {
		padding: 5px;
		font-weight: 900 !important;
		color: rgb(50, 76, 126);
	}
	.actionItem {
		border-radius: 5px;
		font-size: 11px;
		padding: 5px;
		margin: 2px;
		cursor: pointer;
		background-color: whitesmoke;
		&:hover {
			a {
				color: var(--bs-hover-color);
			}
		}
	}
	.actionNone {
		padding: 7px;
		pointer-events: none;
	}
	.actionRed {
		background: #c2272e;
		a {
			color: white;
			&:hover {
				color: white !important;
			}
		}
	}
	.actionItem.actionRed {
		&:hover {
			a {
				color: white !important;
			}
		}
	}
}

.message-heading-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	height: 53px;
}
.message-heading {
	position: relative;
	padding: 19px 0 12px;
	border-bottom: 1px solid #e2e3e4;
	.filter-and-search {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	a {
		display: inline-block;
		vertical-align: middle;
	}
	i {
		font-size: 21px;
	}
	.all-messages-opener {
		position: relative;
		padding-right: 15px;
		&:after {
			position: absolute;
			right: 0;
			content: "\f2f2";
			font-size: 16px;
			font-family: "Material-Design-Iconic-Font";
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			z-index: 1;
			color: #818181;
		}
	}
	.dot {
		position: relative;
		top: -3px;
		margin-left: 18px;
		font-size: 18px;
	}
	.filter-by-attachment {
		height: 20px;
		margin-top: 10px;
		margin-left: 5px;
		.name {
			max-width: 330px;
		}
	}
}

.message-heading.content {
	display: block;
	padding-bottom: 0;
	margin-bottom: 8px;
	.dot {
		position: absolute;
		right: 14px;
		top: 18px;
		line-height: .5;
		padding-bottom: 10px;
		z-index: 2;
	}
}

.trustifi-loader {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px; /* container width*/
	height: 150px; /* container height*/
	max-height: 150px;
	max-width: 150px;
	.trustifi-loader-animation {
		display: block;
		width: 80%;
		height: 80%;
		border-radius: 50%;
		border: solid;
		border-width: 3px;
		border-color: var(--bs-primary) transparent var(--bs-primary) transparent;
		animation: trustifi-loader-animation 1.2s linear infinite;
	}
	.trustifi-logo-loader {
		width: 40%;

		path {
			fill: var(--bs-primary);
		}
	}
	.center {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
	}
}
@keyframes trustifi-loader-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.message-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0 13px;
}
.message-block.alt {
	padding: 10px;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 56px;
	.message-box {
		width: auto;
		text-align: left;
		margin: 0 30px 0 0;
		&:last-child {
			width: 50%;
		}
	}
	a {
		-webkit-transition: color .3s ease-in-out;
		transition: color .3s ease-in-out;
		display: inline;
		padding: 0;
		margin: 0;
		background: transparent;
		//color: #000;
	}
}

.attachment-link {
	font-weight: bold;
	&:hover {
		text-decoration: underline;
	}
}

.several-cols {
	max-width: 585px;
	>a {
		display: block;
		padding-bottom: 12px;
	}
	.several-cols__holder {
		>a {
			display: block;
			padding-bottom: 12px;
		}
		.date-box {
			&:after {
				-webkit-transform: rotate(-180deg);
				-ms-transform: rotate(-180deg);
				transform: rotate(-180deg);
			}
		}
	}
}
.several-cols__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	a {
		padding-bottom: 10px;
	}
	.name {
		display: block;
		width: 18%;
		padding: 0 10px 0 0;
	}
	.info {
		display: block;
		width: 54%;
		padding: 0 10px 0 0;
	}
	.date-box {
		position: relative;
		width: auto;
		padding: 0 15px 0 0;
		&:after {
			position: absolute;
			right: 6px;
			content: "\f2f2";
			font-size: 16px;
			font-family: "Material-Design-Iconic-Font";
			top: -1px;
			z-index: 1;
			color: #818181;
		}
	}
}
.content-info-list {
	list-style: none;
	color: #808080;
	padding-left: 15px;
	margin: 0 0 12px;
	li {
		margin-bottom: 3px;
		font-size: 11px;
	}
}
.autocomplete-wrap.active {
	input {
		border-color: #324c7e;
	}
	.autocomplete-list {
		display: block;
	}
	.email {
		display: block;
	}
}
.autocomplete-wrap.no-matching {
	&:after {
		color: #000;
	}
	input {
		border-color: #59121e;
	}
	.matching-list {
		display: block;
	}
	.email {
		display: block;
	}
}
.autocomplete-wrap {
	.matching-list {
		font-style: italic;
	}
}
.tooltip {
	position: absolute;
	top: 37px;
	left: 100%;
	width: 39%;
	color: #999;
	margin-left: 20px;
}

.attachment-wrap.active {
	.attachment-items {
		display: block;
	}
}
.attachment-wrap {
	.attachment-items {
		.title {
			display: block;
			margin-bottom: 12px;
		}
	}
}
.attachment-popup {
	position: relative;
	.opener {
		&:before {
			font-weight: 700;
			padding-right: 10px;
		}
	}
	.popup {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 200px;
		border: 1px solid #818181;
		background: #fff;
		margin-top: 8px;
		padding: 8px 0;
		z-index: 1;
		box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, .35);
		&:before {
			height: 0;
			width: 0;
			border-bottom: 5px solid #fff;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			position: absolute;
			content: "";
			top: -5px;
			left: 11px;
			z-index: 2;
		}
		&:after {
			height: 0;
			width: 0;
			border-bottom: 6px solid #818181;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			position: absolute;
			content: "";
			top: -6px;
			left: 10px;
		}
	}
}
.attachment-popup.active {
	.opener {
		color: #324c7e;
	}
	.popup {
		display: block;
	}
}
option[value=""][disabled] {
	display: none;
}
option {
	color: inherit;
}
.flex-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.check-col {
	display: inline-block;
	vertical-align: top;
	width: 50%;
	padding-right: 10px;
	margin-right: -3px;
	label {
		display: block;
		text-transform: uppercase;
		font-family: "Roboto Condensed", "Helvetica Neue", "Helvetica", sans-serif;
		margin-bottom: 5px;
	}
	.check-row {
		color: #928e8d;
	}
}
.check-row {
	margin-bottom: 12px;
	color: #d9d9d9;
	font-size: 12px;
	.title {
		text-transform: uppercase;
		font-weight: 700;
		display: block;
		margin-bottom: 5px;
		color: #000;
	}
	p {
		margin-bottom: 5px;
	}
	a {
		display: inline-block;
		margin-bottom: 10px;
	}
	label {
		display: inline-block;
		vertical-align: top;
		&:hover {
			input {
				+ {
					.fake-input {
						opacity: .6 !important;
					}
				}
				~ {
					.fake-label {
						opacity: .6 !important;
					}
				}
			}
			.fake-input {
				cursor: pointer !important;
			}
			.fake-label {
				color: #000 !important;
				cursor: pointer !important;
			}
		}
	}
	input[type="checkbox"] {
		position: fixed;
		left: 0;
		top: 0;
		opacity: 0;
		z-index: -1;
		&:checked {
			+ {
				.fake-input {
					background: #324c7e;
					background: -webkit-linear-gradient(left, var(--bs-hover-color) 50%, #486db5 50%, var(--bs-hover-color) 100%);
					background: linear-gradient(to right, var(--bs-hover-color) 50%, #486db5 50%, var(--bs-hover-color) 100%);
					font-size: 0;
					&:before {
						opacity: 1;
					}
					&:after {
						opacity: 1;
					}
				}
			}
			~ {
				.fake-label {
					color: #000;
					mark {
						color: #486db5;
					}
				}
			}
		}
		&:disabled {
			+ {
				.fake-input {
					background: #ccc;
					pointer-events: none;
					opacity: 1 !important;
				}
			}
			&:checked {
				+ {
					.fake-input {
						background: #324c7e;
						pointer-events: none;
						opacity: 1 !important;
					}
				}
			}
			~ {
				.fake-label {
					color: #b3b3b3;
					pointer-events: none;
					opacity: 1 !important;
					mark {
						color: #b3b3b3;
					}
				}
			}
		}
	}
	.fake-input {
		-webkit-transition: opacity .3s ease-in-out;
		transition: opacity .3s ease-in-out;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		background: #999;
		border-radius: 50%;
		margin-right: 3px;
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		line-height: 20px;
		overflow: hidden;
		&:before {
			position: absolute;
			top: 0;
			opacity: 0;
			content: "\f26b";
			font-family: "Material-Design-Iconic-Font";
			font-size: 13px;
			left: 0;
			width: 20px;
			height: 20px;
			z-index: 2;
		}
	}
	.fake-label {
		-webkit-transition: opacity .3s ease-in-out;
		transition: opacity .3s ease-in-out;
		text-transform: none;
		color: #000;
		&:hover {
			color: #818181 !important;
		}
		mark {
			color: #324c7e;
			background-color: transparent;
		}
	}
}
.checkbox-block {
	margin-bottom: 15px;
	.title {
		display: block;
		margin-bottom: 10px;
	}
	.holder {
		margin-bottom: 4px;
		label {
			display: inline-block;
			vertical-align: middle;
		}
	}
	.select-wrap {
		width: 51px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 4px;
	}
}
.opener {
	font-size: 12px;
}
.redborder.fa {
	width: 110px;
}
.redborder.download-all {
	width: 120px;
	margin: 0 0 0 4px;
}
.redborder {
	border: 1px solid;
	font-size: 10px;
	background-color: #FFFFFF;
	padding: 6px 2px;
	margin: 35px 0 -35px;
	width: auto;
	outline: 0;
	border-radius: 5px;
	opacity: 0.8;
	font-family: Verdana, Geneva, sans-serif;
	font-weight: 400;
	&:hover {
		border: 1px solid rgba(0,0,0,0.15);
		font-weight: 600 !important;
		opacity: 1 !important;
	}
}
.redborder.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.dropdown.disabled, .single-button.disabled {
	opacity: 0.5;
	pointer-events: none;
}
.dropdown-button, .single-button {
	cursor: pointer;
	&:hover {
		// filter: brightness(105%); <--- makes the font blurry
	}
}
address {
	font-style: normal;
}
.summary-status {
	padding: 10px 2px;
	.status-text {
		font-size: 14px;
		font-weight: 500;
	}
	.fa-check-circle {
		font-size: 17px;
		color: green;
	}
	.fa-history {
		background-color: orange;
		padding: 4px;
		font-size: 13px;
		border-radius: 50%;
	}
	.refresh-btn {
		background-color: white;
		padding: 6px 8px;
		border: var(--bs-primary) solid 2px;
		border-radius: 5px;
		cursor: pointer;
		font-size: 12px;
		.fa-sync-alt {
			color: var(--bs-primary);
			margin-left: 2px;
		}
	}
}
.summary-section {
	.summary-boxes-container {
		.box-summary-container {
			width: calc(50% - (30px / 2));
		}
		.summary-box {
			border: var(--bs-hover-color) 1px solid;
			overflow: hidden;
			flex-grow: 1;
			.summary-box-title {
				background-color: var(--bs-hover-color);
			}
			.summary-box-tab {
				&:nth-of-type(odd) {
					background-color: #d3d3d37d;
				}
				&:after {
					border-bottom: unset;
				}
			}
			.event-and-recipients:after {
				border-bottom: unset;
			}
		}
	}
	.summary-map-container {
		margin-top: 18px;
		background-color: #d3d3d340;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		border: 1px solid #d3d3d340;
	}
	@media screen and (max-width: 1550px) {
		.summary-boxes-container{
			.box-summary-container { width: 100%; }
			.summary-box {
				width: 100%;
				min-width: 340px;
				max-height: 300px;
			}
		}
	}
}
popup {
	outline: none;
	.usage-headers {
		font-weight: 600;
	}
	.usage-rows {
		max-height: 425px;
		overflow-y: auto;
	}

	.sensitive-row {
		display: flex;
		margin-bottom: 5px;
		height: 20px;
		&.no-height {
			height: unset;
		}
		.header {
			font-size: 15px;
			font-weight: bold;
			min-width: 150px;
		}
		.value {
			line-height: 20px;

			&.long {
				word-break: break-all;
			}
		}
	}

	.sensitive-message {
		position: relative;
		top: 15px;
		color: green;
	}
}

.paypal-wrapper {
	width: 370px;
}

@media screen and (max-width: 850px) {
	.paypal-wrapper {
		width: 80vw;
	}
}

.signup-page {
	.heading {
		background: transparent;
		&:hover {
			background: transparent;
		}
	}
	.content-holder {
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			background: url("https://i.pinimg.com/originals/c8/03/fc/c803fc222a068eeefed11bdabe9b75bf.jpg");
			z-index: 1;
			opacity: 0.3;
			background-size: 100%;
			background-position: center;
		}
	}
	.commercial-content-container {
		&:after {
			position: absolute;
			content: "";
			background: url("https://i.imgur.com/nSK9m86.png");
			height: 59%;
			width: 127%;
			top: 40%;
			opacity: 0.35;
			right: -40%;
			background-size: cover;
		}
		.commercial-text-blocks-wrapper {
			z-index: 20;
			> * {
				background: linear-gradient(90deg, rgba(0,0,0,0.45), rgba(0,0,0,0.09));
				display: flex;
				flex-direction: row;
				&:nth-child(even) {
					background: linear-gradient(-90deg, rgba(0,0,0,0.45), rgba(0,0,0,0.09));
					flex-direction: row-reverse;
					justify-content: space-between;
					> div:first-child {
						text-align: end;
						margin-left: 30px;
					}
				}
			}
		}
	}
	.form-container:after {
		content: "";
		position: absolute;
		top: -10px;
		bottom: -10px;
		left: -10px;
		right: -10px;
		z-index: -1;
		box-shadow: 0 0 10px lightgrey;
		border-radius: 10px;
		background: rgba(255,255,255,0.38);
	}

	// mobile
	@media screen and (max-width: 480px) {
		.content-holder {
			border-radius: 0 !important;
		}
		.login-form {
			padding: 15px !important;
		}
		.paypal-wrapper {
			margin: auto !important;
		}
		.heading {
			background: #f5f5f5 !important;
			padding: 0 !important;
			display: flex !important;
			justify-content: center !important;
			align-items: center !important;
			> img {
				margin: 0 !important;
			}
		}
		.header-menu-button {
			display: none !important;
		}
		#main {
			padding: 0 !important;
		}
		.form-container {
			flex-direction: column-reverse !important;
			width: 100% !important;
			border-radius: 0 !important;
			&:after {
				display: none;
			}
			> div:nth-child(2) {
				padding: 20px 2vw 10vh !important;
			}
			> div {
				height: auto !important;
				border-radius: 0 !important;
			}
		}
		.commercial-content-container {
			padding: 10vh 0 0 !important;
			.title-box .title {
				font-size: 8vw !important;
				padding: 15px !important;
			}
			.subtitle {
				padding: 0 15px !important;
				margin: 0 0 5vh !important;
			}
			&:after {
				width: 121% !important;
				top: 50% !important;
				right: 0 !important;
				opacity: 0.2 !important;
			}
		}
		.commercial-text-blocks-wrapper {
			> * {
				background: linear-gradient(90deg, rgba(0,0,0,0.45), rgba(0,0,0,0.29));
				padding: 15px !important;
				flex-direction: column !important;

				> div {
					width: unset !important;
					&:first-child {
						margin:0 0 30px !important;
					}
				}
				&:nth-child(even) {
					background: linear-gradient(-90deg, rgba(0,0,0,0.45), rgba(0,0,0,0.29));
					flex-direction: column !important;
					> div:first-child {
						align-self: flex-end !important;
					}
				}
			}
		}
	}
}

a.area {
	position: relative;
	&:before {
		position: absolute;
		content: '';
		top: -5px;
		right: -5px;
		left: -5px;
		bottom: -5px;
	}
}
a.area-s {
	position: relative;
	&:before {
		position: absolute;
		content: '';
		top: -2px;
		right: -2px;
		left: -2px;
		bottom: -2px;
	}
}
.hidepwd {
	input[type="checkbox"] {
		display: none;
		&:checked {
			~ {
				.checked {
					display: inline-block;
				}
				.unchecked {
					display: none;
				}
			}
		}
	}
	.checked {
		display: none;
	}
}
@media (min-width: 988px) {
	.form {
		.label-col {
			vertical-align: top;
			width: 182px;
		}
		.label-col-btns {
			vertical-align: top;
			width: 350px;
		}
	}
}
@media (min-width: 1024px) {
	.message-form {
		.label-col {
			display: inline-block;
			vertical-align: top;
			width: 182px;
		}
	}
	.tooltip {
		margin-left: 20px;
		width: 255px;
	}
	.check-col {
		padding: 11px 7px 0 7px;
	}
	.submit-row {
		width: 593px;
		padding: 29px 0 26px 183px;
		.reset-group {
			float: right;
			padding-right: 2px;
			input {
				margin: 0 19px;
			}
		}
	}
	.signin-form {
		.label-col {
			display: inline-block;
			vertical-align: top;
			width: 182px;
		}
		.col.big-col {
			width: 452px;
		}
		.col {
			width: 308px;
		}
		.ready-info-wrap {
			padding-left: 10px;
		}
		.select-wrap.short {
			width: 308px;
		}
	}
	.form {
		.col.big-col {
			width: 452px;
		}
		.col {
			width: 308px;
		}
		.ready-info-wrap {
			padding-left: 10px;
			.box-with-danger {
				padding-top: 49px;
			}
		}
		.check-item {
			.description-popup {
				right: 12px;
			}
		}
	}
	.danger-icon {
		left: -22px;
	}
	.account-list {
		li {
			.danger-icon {
				left: -22px !important;
			}
		}
	}
	.circle-list {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		padding: 0;
		width: 430px;
		margin: 0 auto;
	}
	.tariff-list {
		width: 814px;
		>li {
			margin: 0;
		}
	}
	.content-wrap {
		.two-cols {
			max-width: inherit;
		}
		.col {
			width: 47%;
		}
	}
	.calendar-list {
		>li {
			width: 9.5%;
		}
	}
}
@media (max-width: 1023px) {
	.message-form {
		.label-col {
			.content-popup {
				right: auto;
				left: 100px;
			}
		}
	}
	.form {
		.continue-wrap {
			width: 308px;
		}
	}
	.account-list {
		li {
			width: 50%;
		}
	}
}

@keyframes TTfadein {
	0% {opacity: 0}
	100% {opacity: 1}
}

i.fa-question-circle:hover {
	opacity: 0.75;
}

body[data-theme='dark'] {
	.note-modal-backdrop {
		display: none !important;
	}

	app-root > popup-drv-component > popup-c > .popup-layer > .popup-frame-outer-container,
	app-root > popup-c > .popup-layer > .popup-frame-outer-container {
		@extend .t-dark-mode;

		padding: 0 !important;
		box-shadow: 0 0 2px white !important;
		border: solid 1px #b7b7b7 !important;
	}

	.Ctooltip {
		@extend .t-dark-mode;

		box-shadow: 0 0 2px white !important;
		border: solid 1px #b7b7b7 !important;
	}

	#wrapper {
		@extend .t-dark-mode;
	}
}

.t-dark-mode {
	filter: brightness(0.67) hue-rotate(-180deg) invert(0.87) contrast(2.34);
    overflow: hidden; // pay attention: filter causes the position:fixed elements to be fixed to it!
    will-change: filter; // Safari bug fix
    .static-color, .color-picker, .intl-tel-input .selected-flag, .intl-tel-input .country-list .flag-box, img, iframe, .input-group-addon .flag {
		filter: saturate(1.45) contrast(0.49) invert(1) hue-rotate(200deg) brightness(1.25) grayscale(30%);
    }

    // danger red
	--bs-danger-rgb: 255, 147, 147;
	--bs-danger: #ff9393;
    // warning orange
	--bs-warning: #997030;
	--bs-warning-rgb: 153, 112, 48;

    .switch-toggle > input {
        & + label:after {
            box-shadow: 0 1px 7px black !important;
        }
        &:not(:checked) + label:before {
            background: #adadad;
        }
    }

	.app-header {
		border-color: transparent;
		background: #cbcbcb;
		--bs-bold-color: black;

		.app-page-title {
			--bs-text-color: black;
		}
	}

    // content popup
	.popup-layer {
		background: rgba(255, 255, 255, 0.65);
		backdrop-filter: blur(5px);

		.popup-frame-outer-container {
			padding: 0;
		}
	}
    // action popup (directive) & other popups (summernote, contacts...)
    .contact-address-book-popup, .note-modal.open {
        background-color: rgba(255,255,255,0.85) !important;
        backdrop-filter: blur(7px) !important;

        .popup-container {
            border: solid 1px black;
        }
    }

    .single-button.color-inverted {
        border-color: black;
        > span, > i {
            color: black;
        }
    }

    .steps-container .step-tab.active, .single-button:not(.color-inverted) span {
        font-weight: 400;
    }

    .ntf-toaster {
		filter: brightness(0.9);
    }

    iframe {
        background: white;

		&#previewframe {
			padding: 1vw;
		}
    }

    .tp-popup-header {
        background: none !important;
    }

	leaflet-c {
		filter: contrast(1.45) brightness(1.45);
	}

    // border instead of shadow
    .trends-data-card, .threat-protection-card, .drop-menu, .integration-icons > div, .popup-background .popup-container, .dropdown, .dropdown.active:hover, .dropdown .dropdown-menu.active,
    .app-sidebar.collapsed .sidebar-submenu-container, .compose-popup-wrap, .support-pages-search-input, .onboarding-setup-icon-design,
    .note-modal .note-modal-content, .popup-frame-outer-container, .filter-container, .create-new-box, .shadow-sm {
        box-shadow: 0 0 2px white !important;
        border: solid 1px #b7b7b7 !important;
    }
	.integration-icons #checkPointImg > img {
		background-color: white;
	}
    .btn, .btn:hover {
        box-shadow: none !important;
    }
    .dropdown > .select {
        padding: Min(4%,7px) Min(5%,7px) !important;
    }

    .listTable {
        background: #fbfbfb;
    }

    ._720kb-datepicker-calendar-month span, ._720kb-datepicker-default-button {
        font-weight: 400;
        color: white;
    }

	.onboarding-setup-icon-design > i {
		filter: saturate(1.45) contrast(0.49) invert(1) hue-rotate(200deg) brightness(1.25) grayscale(30%) !important;
	}

	.apexcharts-radial-series, .apexcharts-bar-series .apexcharts-series, .apexcharts-legend-marker, .apexcharts-pie .apexcharts-series {
		opacity: 0.45;
	}
	.apexcharts-datalabels {
		filter: invert(1);
	}

	.phishing-icon-img {
		filter: none;
	}

	.country-code .input-group .dropdown  {
		border-color: #cccccc !important;

		.dropbtn {
			border: none !important;
			height: 28px !important;
		}
	}
}

router-outlet, router-outlet + * {
	display: contents;
}

.bubbles-animation {
	position: relative;
	height: clamp(200px, 300px, 50%);
	flex: unset;
	width: 100%;
	background: linear-gradient(0deg, white, #d4f1ff);
	overflow: hidden;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(0deg, white, transparent 11%, transparent);
	}

	.animation-content{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display:block;
		animation: MoveUpDown 10s ease-in-out forwards;
		-webkit-animation: MoveUpDown 10s ease-in-out forwards;
	}

	#bubbles {
		transform: rotate(-90deg);
	}

	.bubble {
		width: 70px;
		height: 70px;
		background: #97e5ff;
		border-radius: 200px;
		-moz-border-radius: 200px;
		-webkit-border-radius: 200px;
		position: absolute;
	}

	.x1 {
		-webkit-transform: scale(0.9);
		-moz-transform: scale(0.9);
		transform: scale(0.9);
		opacity: 0.2;
		-webkit-animation: moveBubblesX 5s linear infinite, moveBubblesY 6s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 5s linear infinite, moveBubblesY 6s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 5s linear infinite, moveBubblesY 6s ease-in-out infinite alternate;
	}

	.x2 {
		top: 200px;
		-webkit-transform: scale(0.6);
		-moz-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 0.5;
		-webkit-animation: moveBubblesX 10s linear infinite, moveBubblesY 8s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 10s linear infinite, moveBubblesY 8s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 10s linear infinite, moveBubblesY 8s ease-in-out infinite alternate;
	}
	.x3 {
		top: 350px;
		-webkit-transform: scale(0.8);
		-moz-transform: scale(0.8);
		transform: scale(0.8);
		opacity: 0.3;
		-webkit-animation: moveBubblesX 10s linear infinite, moveBubblesY 4s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 10s linear infinite, moveBubblesY 4s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 10s linear infinite, moveBubblesY 4s ease-in-out infinite alternate;
	}

	.x4 {
		top: 570px;
		-webkit-transform: scale(0.75);
		-moz-transform: scale(0.75);
		transform: scale(0.75);
		opacity: 0.35;
		-webkit-animation: moveBubblesX 8s linear infinite, moveBubblesY 3s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 8s linear infinite, moveBubblesY 3s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 8s linear infinite, moveBubblesY 3s ease-in-out infinite alternate;
	}
	.x5 {
		top: 150px;
		-webkit-transform: scale(0.8);
		-moz-transform: scale(0.8);
		transform: scale(0.8);
		opacity: 0.3;
		-webkit-animation: moveBubblesX 7s linear infinite, moveBubblesY 5s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 7s linear infinite, moveBubblesY 5s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 7s linear infinite, moveBubblesY 5s ease-in-out infinite alternate;
	}

	.x6 {
		top: 770px;
		-webkit-transform: scale(0.9);
		-moz-transform: scale(0.9);
		transform: scale(0.9);
		opacity: 0.2;
		-webkit-animation: moveBubblesX 5s linear infinite, moveBubblesYOpposite 4s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 5s linear infinite, moveBubblesYOpposite 4s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 5s linear infinite, moveBubblesYOpposite 4s ease-in-out infinite alternate;
	}

	.x7 {
		top: 200px;
		-webkit-transform: scale(0.6);
		-moz-transform: scale(0.6);
		transform: scale(0.6);
		opacity: 0.5;
		-webkit-animation: moveBubblesX 11s linear infinite, moveBubblesYOpposite 3s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 11s linear infinite, moveBubblesYOpposite 3s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 11s linear infinite, moveBubblesYOpposite 3s ease-in-out infinite alternate;
	}
	.x8 {
		top: 950px;
		-webkit-transform: scale(0.8);
		-moz-transform: scale(0.8);
		transform: scale(0.8);
		opacity: 0.3;
		-webkit-animation: moveBubblesX 6s linear infinite, moveBubblesYOpposite 6s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 6s linear infinite, moveBubblesYOpposite 6s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 6s linear infinite, moveBubblesYOpposite 6s ease-in-out infinite alternate;
	}

	.x9 {
		top: 870px;
		-webkit-transform: scale(0.75);
		-moz-transform: scale(0.75);
		transform: scale(0.75);
		opacity: 0.35;
		-webkit-animation: moveBubblesX 15s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 15s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 15s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
	}
	.x10 {
		top: 150px;
		-webkit-transform: scale(0.8);
		-moz-transform: scale(0.8);
		transform: scale(0.8);
		opacity: 0.3;
		-webkit-animation: moveBubblesX 10s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
		-moz-animation: moveBubblesX 10s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
		-o-animation: moveBubblesX 10s linear infinite, moveBubblesYOpposite 5s ease-in-out infinite alternate;
	}

	#bubbles {
		height: 100%;
		padding: 0;
	}

	#phishingHookString {
		width: 22px;
		position: absolute;
		top: -212px;
		left: 94px;
		transform-origin: 50% 0;
		animation: rotateAbit2 5s ease-in-out forwards, 5s rotateMore2 5s ease-in-out infinite;
		-webkit-animation: rotateAbit2 5s ease-in-out forwards, 5s rotateMore2 5s ease-in-out infinite ;
	}

	#phishingHook{
		width: 100px;
		animation: rotateAbit 5s ease-in-out forwards, 5s rotateMore 5s ease-in-out infinite;
		-webkit-animation: rotateAbit 5s ease-in-out forwards, 5s rotateMore 5s ease-in-out infinite ;

		transform-origin: 108px 0;
	}

	@keyframes rotateAbit {
		0% {transform: rotate(61deg);}
		18% {transform:  rotate(46deg);}
		35% {transform:  rotate(-33deg);}
		50% {transform:  rotate(-17deg);}
		75% {transform:  rotate(-30deg);}
		100% {transform:  rotate(-10deg);}
	}

	@keyframes rotateAbit2 {
		0% {transform: rotate(4deg) translateY(-28px);}
		18% {transform:  rotate(8deg) translateY(-24px);}
		35% {transform:  rotate(8deg) translateX(13px) translateY(7px);}
		50% {transform:  rotate(5deg);}
		75% {transform:  rotate(5deg);}
		100% {transform:  rotate(6deg);}
	}

	@keyframes MoveUpDown {
		0% { transform: translateY(calc(-250px - 50%)) translateX(calc(10px - 50%)); }
		12.5% { transform:  translateY(calc(-25px - 50%)) translateX(calc(10px - 50%)); }
		50% { transform:  translateY(calc(12px - 50%)) translateX(calc(-15px - 50%)); }
		75% { transform:  translateY(calc(-20px - 50%)) translateX(calc(15px - 50%)); }
		100% { transform:  translateY(-50%) translateX(-50%); }
	}

	@keyframes rotateMore {
		0% {transform:  rotate(-10deg);}
		50% {transform:  rotate(-30deg);}
		100% {transform:  rotate(-10deg);}
	}

	@keyframes rotateMore2 {
		0% {transform:  rotate(6deg);}
		50% {transform:  rotate(4deg);}
		100% {transform:  rotate(6deg);}
	}

	@keyframes moveBubblesX {
		0% {
			left: -1%;
		}
		100% {
			left: 101%;
		}
	}

	@keyframes moveBubblesY {
		0% {
			margin-top:0;
		}
		100% {
			margin-top:150px;
		}
	}

	@keyframes moveBubblesYOpposite {
		0% {
			margin-top:0;
		}
		100% {
			margin-top:-100px;
		}
	}
}
